interface DomainToLocaleData {
  [key: string]: string;
}

export const domainToLocaleData: DomainToLocaleData = {
  '.ae': 'ar',
  '.ai': 'en',
  '.cn': 'zh',
  '.uk': 'en',
  '.com': 'en',
  '.de': 'de',
  '.dk': 'da',
  '.fr': 'fr',
  '.gr': 'el',
  '.in': 'hi',
  '.it': 'it',
  '.jp': 'ja',
  '.kr': 'ko',
  '.lt': 'lt',
  '.lv': 'lv',
  '.net': 'en',
  '.nz': 'en',
  '.pl': 'pl',
  '.pt': 'pt',
  '.se': 'sv',
  '.store': 'en',
  '.tech': 'en',
  '.website': 'en',
};
