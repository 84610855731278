export const CREATE_USER_ANSWER_START = 'CREATE_USER_ANSWER_START';
export const CREATE_USER_ANSWER_SUCCESS = 'CREATE_USER_ANSWER_SUCCESS';
export const CREATE_USER_ANSWER_FAIL = 'CREATE_USER_ANSWER_FAIL';

export const USER_ANSWER_RESET_STORE = 'USER_ANSWER_RESET_STORE';

export const LOGOUT = 'LOGOUT';

export type UserAnswerActionTypes =
  | typeof CREATE_USER_ANSWER_START
  | typeof CREATE_USER_ANSWER_SUCCESS
  | typeof CREATE_USER_ANSWER_FAIL
  | typeof USER_ANSWER_RESET_STORE
  | typeof LOGOUT;
