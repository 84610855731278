import React, { ReactNode } from 'react';
import { routes } from '../../../config/Router/routes';
import PublicNavigation from '../../PublicNavigation/PublicNavigation';
import Footer from '../../Footer/Footer';

type Props = {
  children: ReactNode;
};

const IGNORED_ROUTES = [routes.comingSoon];

const PublicLayout = ({ children }: Props) => {
  const isRouteIgnored = IGNORED_ROUTES.find((ignoredRoute) =>
    location.pathname.includes(ignoredRoute),
  );

  if (isRouteIgnored) {
    return <>{children}</>;
  }

  return (
    <>
      <PublicNavigation />
      {children}
      <Footer />
    </>
  );
};

export default PublicLayout;
