export const FETCH_TRAITS_START = 'FETCH_TRAITS_START';
export const FETCH_TRAITS_SUCCESS = 'FETCH_TRAITS_SUCCESS';
export const FETCH_TRAITS_FAIL = 'FETCH_TRAITS_FAIL';

export const FETCH_TRAIT_START = 'FETCH_TRAIT_START';
export const FETCH_TRAIT_SUCCESS = 'FETCH_TRAIT_SUCCESS';
export const FETCH_TRAIT_FAIL = 'FETCH_TRAIT_FAIL';

export const CREATE_TRAIT_START = 'CREATE_TRAIT_START';
export const CREATE_TRAIT_SUCCESS = 'CREATE_TRAIT_SUCCESS';
export const CREATE_TRAIT_FAIL = 'CREATE_TRAIT_FAIL';

export const UPDATE_TRAIT_START = 'UPDATE_TRAIT_START';
export const UPDATE_TRAIT_SUCCESS = 'UPDATE_TRAIT_SUCCESS';
export const UPDATE_TRAIT_FAIL = 'UPDATE_TRAIT_FAIL';

export const DELETE_TRAIT_START = 'DELETE_TRAIT_START';
export const DELETE_TRAIT_SUCCESS = 'DELETE_TRAIT_SUCCESS';
export const DELETE_TRAIT_FAIL = 'DELETE_TRAIT_FAIL';

export const FETCH_ALL_TRAITS_START = 'FETCH_ALL_TRAITS_START';
export const FETCH_ALL_TRAITS_SUCCESS = 'FETCH_ALL_TRAITS_SUCCESS';
export const FETCH_ALL_TRAITS_FAIL = 'FETCH_ALL_TRAITS_FAIL';

export const RESET_TRAIT_STORE = 'RESET_TRAIT_STORE';
export const RESET_CREATED_TRAIT = 'RESET_CREATED_TRAIT';
export const RESET_UPDATED_TRAIT = 'RESET_UPDATED_TRAIT';

export const LOGOUT = 'LOGOUT';

export type TraitActionTypes =
  | typeof FETCH_TRAITS_START
  | typeof FETCH_TRAITS_SUCCESS
  | typeof FETCH_TRAITS_FAIL
  | typeof FETCH_TRAIT_START
  | typeof FETCH_TRAIT_SUCCESS
  | typeof FETCH_TRAIT_FAIL
  | typeof CREATE_TRAIT_START
  | typeof CREATE_TRAIT_SUCCESS
  | typeof CREATE_TRAIT_FAIL
  | typeof UPDATE_TRAIT_START
  | typeof UPDATE_TRAIT_SUCCESS
  | typeof UPDATE_TRAIT_FAIL
  | typeof DELETE_TRAIT_START
  | typeof DELETE_TRAIT_SUCCESS
  | typeof DELETE_TRAIT_FAIL
  | typeof FETCH_ALL_TRAITS_START
  | typeof FETCH_ALL_TRAITS_SUCCESS
  | typeof FETCH_ALL_TRAITS_FAIL
  | typeof RESET_TRAIT_STORE
  | typeof RESET_CREATED_TRAIT
  | typeof RESET_UPDATED_TRAIT
  | typeof LOGOUT;
