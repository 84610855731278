import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { UserAssessmentActionTypes } from './actionTypes';
import { Question } from '../../domain/Question';
import { AssessmentResults } from '../../domain/Assessment';
import {
  AnsweredQuestion,
  Respondent,
  UserAssessment,
} from '../../domain/UserAssessment';

export type UserAssessmentStateType = {
  currentQuestionLoading: boolean;
  currentQuestionError: HttpError;
  currentQuestion: Question | null;
  currentQuestionSuccess: boolean;
  assessmentTokens: UserAssessmentToken[];
  createUserAssessmentSuccess: boolean;
  createUserAssessmentLoading: boolean;
  createUserAssessmentError: HttpError;
  createdRespondent: UserAssessment | null;
  createRespondentLoading: boolean;
  createRespondentError: HttpError;
  completeUserAssessmentSuccess: boolean;
  completeUserAssessmentLoading: boolean;
  completeUserAssessmentError: HttpError;
  resultsLoading: boolean;
  resultsError: HttpError;
  results: AssessmentResults | null;
  respondents: Respondent[];
  respondentsLoading: boolean;
  respondentsError: HttpError;
};

export type UserAssessmentToken = {
  assessmentId: number;
  token: string;
  answeredQuestions: AnsweredQuestion[];
  startedAt?: Date;
};

export type UserAssessmentActionType = UserAssessmentStateType & {
  type: UserAssessmentActionTypes;
  userAssessmentToken: UserAssessmentToken;
};

export const initialState: UserAssessmentStateType = {
  currentQuestionLoading: false,
  currentQuestionError: null,
  currentQuestion: null,
  currentQuestionSuccess: false,
  createUserAssessmentSuccess: false,
  createUserAssessmentLoading: false,
  createUserAssessmentError: null,
  createdRespondent: null,
  createRespondentLoading: false,
  createRespondentError: null,
  completeUserAssessmentSuccess: false,
  completeUserAssessmentLoading: false,
  completeUserAssessmentError: null,
  assessmentTokens: localStorage.getItem('assessmentToken')
    ? JSON.parse(localStorage.getItem('assessmentToken') ?? '')
    : [],
  resultsLoading: false,
  resultsError: null,
  results: null,
  respondents: [],
  respondentsLoading: false,
  respondentsError: null,
};

const fetchCurrentQuestionStart = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...state,
  currentQuestionLoading: true,
  currentQuestionSuccess: false,
});

const fetchCurrentQuestionSuccess = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => ({
  ...state,
  currentQuestion: action.currentQuestion,
  currentQuestionLoading: false,
  currentQuestionError: null,
  currentQuestionSuccess: true,
});

const fetchCurrentQuestionFail = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => ({
  ...state,
  currentQuestionError: action.currentQuestionError,
  currentQuestionLoading: false,
  currentQuestionSuccess: false,
});

const createUserAssessmentStart = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...state,
  createUserAssessmentLoading: true,
});

const createUserAssessmentSuccess = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...state,
  createUserAssessmentLoading: false,
  createUserAssessmentError: null,
  createUserAssessmentSuccess: true,
});

const createUserAssessmentFail = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => ({
  ...state,
  createUserAssessmentError: action.createUserAssessmentError,
  createUserAssessmentLoading: false,
});

const createRespondentStart = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...state,
  createRespondentLoading: true,
  createdRespondent: null,
});

const createRespondentSuccess = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => ({
  ...state,
  createRespondentLoading: false,
  createRespondentError: null,
  createdRespondent: action.createdRespondent,
});

const createRespondentFail = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => ({
  ...state,
  createRespondentError: action.createRespondentError,
  createRespondentLoading: false,
});

const createRespondentReset = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...state,
  createdRespondent: null,
  createRespondentLoading: false,
  createRespondentError: null,
});

const completeUserAssessmentStart = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...state,
  completeUserAssessmentLoading: true,
});

const completeUserAssessmentSuccess = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...state,
  completeUserAssessmentLoading: false,
  completeUserAssessmentError: null,
  completeUserAssessmentSuccess: true,
});

const completeUserAssessmentFail = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => ({
  ...state,
  completeUserAssessmentError: action.completeUserAssessmentError,
  completeUserAssessmentLoading: false,
});

const setUserAssessmentToken = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => {
  const assessmentTokens = state.assessmentTokens.find(
    (assessmentToken) =>
      assessmentToken.token === action.userAssessmentToken.token,
  )
    ? state.assessmentTokens.map((assessmentToken) => {
        if (assessmentToken.token === action.userAssessmentToken.token) {
          return action.userAssessmentToken;
        }

        return assessmentToken;
      })
    : [
        ...state.assessmentTokens,
        { ...action.userAssessmentToken, startedAt: new Date() },
      ];

  localStorage.setItem('assessmentToken', JSON.stringify(assessmentTokens));

  return {
    ...state,
    assessmentTokens: assessmentTokens,
    createUserAssessmentSuccess: false,
  };
};

const fetchResultsStart = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...state,
  resultsLoading: true,
});

const fetchResultsSuccess = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => ({
  ...state,
  results: action.results,
  resultsLoading: false,
  resultsError: null,
});

const fetchResultsFail = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => ({
  ...state,
  resultsError: action.resultsError,
  resultsLoading: false,
});

const fetchRespondentsStart = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...state,
  respondentsLoading: true,
});

const fetchRespondentsSuccess = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => ({
  ...state,
  respondents: action.respondents,
  respondentsLoading: false,
  respondentsError: null,
});

const fetchRespondentsFail = (
  state: UserAssessmentStateType,
  action: UserAssessmentActionType,
): UserAssessmentStateType => ({
  ...state,
  respondentsError: action.respondentsError,
  respondentsLoading: false,
});

const resetRespondents = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...state,
  respondents: [],
  respondentsLoading: false,
  respondentsError: null,
});

const resetUserAssessmentStore = (
  state: UserAssessmentStateType,
): UserAssessmentStateType => ({
  ...initialState,
  assessmentTokens: state.assessmentTokens,
});

const completelyResetUserAssessmentStore = (): UserAssessmentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: UserAssessmentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CURRENT_QUESTION_START:
      return fetchCurrentQuestionStart(state);
    case actionTypes.FETCH_CURRENT_QUESTION_SUCCESS:
      return fetchCurrentQuestionSuccess(state, action);
    case actionTypes.FETCH_CURRENT_QUESTION_FAIL:
      return fetchCurrentQuestionFail(state, action);
    case actionTypes.CREATE_USER_ASSESSMENT_START:
      return createUserAssessmentStart(state);
    case actionTypes.CREATE_USER_ASSESSMENT_SUCCESS:
      return createUserAssessmentSuccess(state);
    case actionTypes.CREATE_USER_ASSESSMENT_FAIL:
      return createUserAssessmentFail(state, action);
    case actionTypes.CREATE_RESPONDENT_START:
      return createRespondentStart(state);
    case actionTypes.CREATE_RESPONDENT_SUCCESS:
      return createRespondentSuccess(state, action);
    case actionTypes.CREATE_RESPONDENT_FAIL:
      return createRespondentFail(state, action);
    case actionTypes.CREATE_RESPONDENT_RESET:
      return createRespondentReset(state);
    case actionTypes.COMPLETE_USER_ASSESSMENT_START:
      return completeUserAssessmentStart(state);
    case actionTypes.COMPLETE_USER_ASSESSMENT_SUCCESS:
      return completeUserAssessmentSuccess(state);
    case actionTypes.COMPLETE_USER_ASSESSMENT_FAIL:
      return completeUserAssessmentFail(state, action);
    case actionTypes.SET_USER_ASSESSMENT_TOKEN_DATA:
      return setUserAssessmentToken(state, action);
    case actionTypes.FETCH_RESULTS_START:
      return fetchResultsStart(state);
    case actionTypes.FETCH_RESULTS_SUCCESS:
      return fetchResultsSuccess(state, action);
    case actionTypes.FETCH_RESULTS_FAIL:
      return fetchResultsFail(state, action);
    case actionTypes.FETCH_RESPONDENTS_START:
      return fetchRespondentsStart(state);
    case actionTypes.FETCH_RESPONDENTS_SUCCESS:
      return fetchRespondentsSuccess(state, action);
    case actionTypes.FETCH_RESPONDENTS_FAIL:
      return fetchRespondentsFail(state, action);
    case actionTypes.RESET_RESPONDENTS:
      return resetRespondents(state);
    case actionTypes.RESET_USER_ASSESSMENT_STORE:
      return resetUserAssessmentStore(state);
    case actionTypes.LOGOUT:
      return completelyResetUserAssessmentStore();
    default:
      return state;
  }
};

export default reducer;
