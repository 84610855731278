import React from 'react';
import styles from './TestPurchaseModal.module.scss';
import Modal from '../../../../common/Modal/Modal';
import PlansSection from '../../HomePageV2/PlansSection/PlansSection';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TestPurchaseModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      className={styles.testPurchaseModal}
      title="&nbsp;"
    >
      <PlansSection isSmaller />
    </Modal>
  );
};

export default TestPurchaseModal;
