import * as actionTypes from './actionTypes';
import { CompanyActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Company } from '../../domain/Company';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';

export type CompanyStateType = {
  companiesList: ListResults<Company> | null;
  companiesLoading: boolean;
  companiesError: HttpError;
  companiesListUpdateNeeded: boolean;
  companyCreateLoading: boolean;
  companyCreateError: HttpError;
  companyCreateSuccess: boolean;
  createdCompany: Company | null;
  companyUpdateLoading: boolean;
  companyUpdateError: HttpError;
  companyUpdateSuccess: boolean;
  company: Company | null;
  companyLoading: boolean;
  companyError: HttpError;
  companyDeleteLoading: boolean;
  companyDeleteError: HttpError;
  companyOptions: Company[];
  companyOptionsLoading: boolean;
  companyOptionsError: HttpError;
  selectedCompany: number | null;
};

export type CompanyActionType = CompanyStateType & {
  type: CompanyActionTypes;
  settings: UserSetting[];
};

export const initialState: CompanyStateType = {
  companiesList: null,
  companiesLoading: true,
  companiesError: null,
  companiesListUpdateNeeded: false,
  companyCreateLoading: false,
  companyCreateError: null,
  companyCreateSuccess: false,
  createdCompany: null,
  companyUpdateLoading: false,
  companyUpdateError: null,
  companyUpdateSuccess: false,
  company: null,
  companyLoading: false,
  companyError: null,
  selectedCompany: null,
  companyDeleteLoading: false,
  companyDeleteError: null,
  companyOptions: [],
  companyOptionsLoading: true,
  companyOptionsError: null,
};

const fetchCompaniesStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companiesLoading: true,
});

const fetchCompaniesSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companiesList: action.companiesList,
  companiesLoading: false,
  companiesError: null,
  companiesListUpdateNeeded: false,
});

const fetchCompaniesFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companiesError: action.companiesError,
  companiesLoading: false,
});

const fetchCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyLoading: true,
  companyUpdateSuccess: false,
  companyCreateSuccess: false,
  companyCreateError: null,
  companyUpdateError: null,
});

const fetchCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  company: action.company,
  companyLoading: false,
  companyError: null,
});

const fetchCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyError: action.companyError,
  companyLoading: false,
});

const createCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyCreateLoading: true,
});

const createCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyCreateLoading: false,
  companyCreateError: null,
  companyCreateSuccess: true,
  createdCompany: action.createdCompany,
  companiesListUpdateNeeded: true,
});

const createCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyCreateLoading: false,
  companyCreateError: action.companyCreateError,
});

const updateCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyUpdateLoading: true,
});

const updateCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyUpdateLoading: false,
  companyUpdateError: null,
  companyUpdateSuccess: true,
  companiesListUpdateNeeded: true,
});

const updateCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyUpdateLoading: false,
  companyUpdateError: action.companyUpdateError,
});

const deleteCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyDeleteLoading: true,
  companiesListUpdateNeeded: false,
});

const deleteCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyDeleteLoading: false,
  companyDeleteError: null,
  companiesListUpdateNeeded: true,
});

const deleteCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyDeleteLoading: false,
  companyDeleteError: action.companyDeleteError,
});

const fetchCompanyOptionsStart = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  companyOptionsLoading: true,
});

const fetchCompanyOptionsSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyOptions: action.companyOptions,
  companyOptionsLoading: false,
  companyOptionsError: null,
});

const fetchCompanyOptionsFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companyOptionsError: action.companyOptionsError,
  companyOptionsLoading: false,
});

const setSelectedCompany = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: action.selectedCompany,
});

const setSelectedSettings = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: Number(
    action.settings.find((setting) => setting.type === UserSettingType.COMPANY)
      ?.value,
  ),
});

const resetCompanyStore = (state: CompanyStateType): CompanyStateType => ({
  ...initialState,
  selectedCompany: state.selectedCompany,
});

const createCompanyReset = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companyCreateLoading: false,
  companyCreateSuccess: false,
  companyCreateError: null,
});

const completelyResetCompanyStore = (): CompanyStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CompanyActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANIES_START:
      return fetchCompaniesStart(state);
    case actionTypes.FETCH_COMPANIES_SUCCESS:
      return fetchCompaniesSuccess(state, action);
    case actionTypes.FETCH_COMPANIES_FAIL:
      return fetchCompaniesFail(state, action);
    case actionTypes.FETCH_COMPANY_START:
      return fetchCompanyStart(state);
    case actionTypes.FETCH_COMPANY_SUCCESS:
      return fetchCompanySuccess(state, action);
    case actionTypes.FETCH_COMPANY_FAIL:
      return fetchCompanyFail(state, action);
    case actionTypes.CREATE_COMPANY_START:
      return createCompanyStart(state);
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return createCompanySuccess(state, action);
    case actionTypes.CREATE_COMPANY_FAIL:
      return createCompanyFail(state, action);
    case actionTypes.UPDATE_COMPANY_START:
      return updateCompanyStart(state);
    case actionTypes.UPDATE_COMPANY_SUCCESS:
      return updateCompanySuccess(state);
    case actionTypes.UPDATE_COMPANY_FAIL:
      return updateCompanyFail(state, action);
    case actionTypes.DELETE_COMPANY_START:
      return deleteCompanyStart(state);
    case actionTypes.DELETE_COMPANY_SUCCESS:
      return deleteCompanySuccess(state);
    case actionTypes.DELETE_COMPANY_FAIL:
      return deleteCompanyFail(state, action);
    case actionTypes.RESET_COMPANY_STORE:
      return resetCompanyStore(state);
    case actionTypes.FETCH_COMPANY_OPTIONS_START:
      return fetchCompanyOptionsStart(state);
    case actionTypes.FETCH_COMPANY_OPTIONS_SUCCESS:
      return fetchCompanyOptionsSuccess(state, action);
    case actionTypes.FETCH_COMPANY_OPTIONS_FAIL:
      return fetchCompanyOptionsFail(state, action);
    case actionTypes.SET_SELECTED_SETTINGS:
      return setSelectedSettings(state, action);
    case actionTypes.SET_SELECTED_COMPANY:
      return setSelectedCompany(state, action);
    case actionTypes.CREATE_COMPANY_RESET:
      return createCompanyReset(state);
    case actionTypes.LOGOUT:
      return completelyResetCompanyStore();
    default:
      return state;
  }
};

export default reducer;
