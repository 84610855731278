import React, { ReactNode, useEffect } from 'react';
import styles from './AuthContainer.module.scss';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';
import circle from '../../../../assets/login-circle.svg';
import checkmark from '../../../../assets/checkmark.svg';
import { useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import { SignUpSectionType } from '../../../../pages/Public/SignUpPage/SignUpPage';
import cx from 'classnames';

type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  title: string;
  subTitle?: string;
  section: SignUpSectionType;
  onChangeSection: (section: SignUpSectionType) => void;
};

export const AuthContainer = ({
  children,
  isAuthenticated,
  title,
  subTitle,
  onChangeSection,
  section,
}: Props) => {
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.publicAssessments);
    }
  }, [isAuthenticated]);

  return (
    <div className={styles.authContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.leftContainerInner}>
          <div className={styles.title}>{title}</div>
          {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
          <div>{children}</div>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div
          className={cx(styles.backgroundImage, {
            [styles.registrationBackground]:
              section === SignUpSectionType.REGISTER,
          })}
        >
          <img src={circle} alt="Circle" className={styles.circleImage} />
        </div>
        <div className={styles.leftContainerInner}>
          <h3 className={styles.headline}>
            {section === SignUpSectionType.LOGIN
              ? translate(intl, 'SIGN_UP.QUESTION')
              : translate(intl, 'SIGN_UP.REGISTER_QUESTION')}
          </h3>
          {section === SignUpSectionType.LOGIN && (
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <img
                  src={checkmark}
                  alt="checkmark"
                  className={styles.checkmark}
                />
                <p>{translate(intl, 'SIGN_UP.ADVANTAGE_1')}</p>
              </li>
              <li className={styles.listItem}>
                <img
                  src={checkmark}
                  alt="checkmark"
                  className={styles.checkmark}
                />
                <p>{translate(intl, 'SIGN_UP.ADVANTAGE_2')}</p>
              </li>
              <li className={styles.listItem}>
                <img
                  src={checkmark}
                  alt="checkmark"
                  className={styles.checkmark}
                />
                <p>{translate(intl, 'SIGN_UP.ADVANTAGE_3')}</p>
              </li>
            </ul>
          )}
          <button
            className={styles.registerButton}
            onClick={
              section === SignUpSectionType.LOGIN
                ? () => onChangeSection(SignUpSectionType.REGISTER)
                : () => onChangeSection(SignUpSectionType.LOGIN)
            }
          >
            {section === SignUpSectionType.LOGIN
              ? translate(intl, 'SIGN_UP.REGISTER')
              : translate(intl, 'SIGN_UP.LOGIN')}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(AuthContainer);
