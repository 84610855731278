export const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LANGUAGE;
export const DEFAULT_ADMIN_LOCALE = 'en';
export const LOCAL_STORAGE_SAVED_ASSESSMENTS = 'savedAssessments';
export const LOCAL_STORAGE_SAVED_PUBLIC_ASSESSMENT_DATA =
  'publicAssessmentData';
export const ASSESSMENT_PROGRESS_BAR_TOP_VALUE = 60;
export const MAX_TAGS = 10;
export const NO_INFO = '-';
export const DETAILED_INFO_PUBLIC_PRICE = 39;
export const TRY_TEST_QUESTIONS = 3;
export const MIN_ANSWERS_FOR_KYC = 3;
