export const FETCH_ASSESSMENTS_START = 'FETCH_ASSESSMENTS_START';
export const FETCH_ASSESSMENTS_SUCCESS = 'FETCH_ASSESSMENTS_SUCCESS';
export const FETCH_ASSESSMENTS_FAIL = 'FETCH_ASSESSMENTS_FAIL';

export const FETCH_PUBLIC_ASSESSMENTS_START = 'FETCH_PUBLIC_ASSESSMENTS_START';
export const FETCH_PUBLIC_ASSESSMENTS_SUCCESS =
  'FETCH_PUBLIC_ASSESSMENTS_SUCCESS';
export const FETCH_PUBLIC_ASSESSMENTS_FAIL = 'FETCH_PUBLIC_ASSESSMENTS_FAIL';

export const FETCH_ASSESSMENT_START = 'FETCH_ASSESSMENT_START';
export const FETCH_ASSESSMENT_SUCCESS = 'FETCH_ASSESSMENT_SUCCESS';
export const FETCH_ASSESSMENT_FAIL = 'FETCH_ASSESSMENT_FAIL';

export const FETCH_RANDOM_ASSESSMENT_START = 'FETCH_RANDOM_ASSESSMENT_START';
export const FETCH_RANDOM_ASSESSMENT_SUCCESS =
  'FETCH_RANDOM_ASSESSMENT_SUCCESS';
export const FETCH_RANDOM_ASSESSMENT_FAIL = 'FETCH_RANDOM_ASSESSMENT_FAIL';

export const FETCH_ASSESSMENT_PREVIEWS_START =
  'FETCH_ASSESSMENT_PREVIEWS_START';
export const FETCH_ASSESSMENT_PREVIEWS_SUCCESS =
  'FETCH_ASSESSMENT_PREVIEWS_SUCCESS';
export const FETCH_ASSESSMENT_PREVIEWS_FAIL = 'FETCH_ASSESSMENT_PREVIEWS_FAIL';

export const CREATE_ASSESSMENT_START = 'CREATE_ASSESSMENT_START';
export const CREATE_ASSESSMENT_SUCCESS = 'CREATE_ASSESSMENT_SUCCESS';
export const CREATE_ASSESSMENT_FAIL = 'CREATE_ASSESSMENT_FAIL';

export const UPDATE_ASSESSMENT_START = 'UPDATE_ASSESSMENT_START';
export const UPDATE_ASSESSMENT_SUCCESS = 'UPDATE_ASSESSMENT_SUCCESS';
export const UPDATE_ASSESSMENT_FAIL = 'UPDATE_ASSESSMENT_FAIL';

export const DELETE_ASSESSMENT_START = 'DELETE_ASSESSMENT_START';
export const DELETE_ASSESSMENT_SUCCESS = 'DELETE_ASSESSMENT_SUCCESS';
export const DELETE_ASSESSMENT_FAIL = 'DELETE_ASSESSMENT_FAIL';

export const FETCH_PUBLIC_ASSESSMENT_START = 'FETCH_PUBLIC_ASSESSMENT_START';
export const FETCH_PUBLIC_ASSESSMENT_SUCCESS =
  'FETCH_PUBLIC_ASSESSMENT_SUCCESS';
export const FETCH_PUBLIC_ASSESSMENT_FAIL = 'FETCH_PUBLIC_ASSESSMENT_FAIL';
export const RESET_PUBLIC_ASSESSMENT = 'RESET_PUBLIC_ASSESSMENT';

export const SET_SAVED_ASSESSMENTS = 'SET_SAVED_ASSESSMENTS';

export const SET_SAVED_PUBLIC_ASSESSMENT_DATA =
  'SET_SAVED_PUBLIC_ASSESSMENT_DATA';
export const RESET_SAVED_PUBLIC_ASSESSMENT_DATA =
  'RESET_SAVED_PUBLIC_ASSESSMENT_DATA';

export const FETCH_ASSESSMENT_CANDIDATES_START =
  'FETCH_ASSESSMENT_CANDIDATES_START';
export const FETCH_ASSESSMENT_CANDIDATES_SUCCESS =
  'FETCH_ASSESSMENT_CANDIDATES_SUCCESS';
export const FETCH_ASSESSMENT_CANDIDATES_FAIL =
  'FETCH_ASSESSMENT_CANDIDATES_FAIL';
export const RESET_ASSESSMENT_CANDIDATES = 'RESET_ASSESSMENT_CANDIDATES';

export const RESET_ASSESSMENT_STORE = 'RESET_ASSESSMENT_STORE';
export const LOGOUT = 'LOGOUT';

export type AssessmentActionTypes =
  | typeof FETCH_ASSESSMENTS_START
  | typeof FETCH_ASSESSMENTS_SUCCESS
  | typeof FETCH_ASSESSMENTS_FAIL
  | typeof FETCH_PUBLIC_ASSESSMENTS_START
  | typeof FETCH_PUBLIC_ASSESSMENTS_SUCCESS
  | typeof FETCH_PUBLIC_ASSESSMENTS_FAIL
  | typeof FETCH_ASSESSMENT_START
  | typeof FETCH_ASSESSMENT_SUCCESS
  | typeof FETCH_ASSESSMENT_FAIL
  | typeof FETCH_RANDOM_ASSESSMENT_START
  | typeof FETCH_RANDOM_ASSESSMENT_SUCCESS
  | typeof FETCH_RANDOM_ASSESSMENT_FAIL
  | typeof FETCH_ASSESSMENT_PREVIEWS_START
  | typeof FETCH_ASSESSMENT_PREVIEWS_SUCCESS
  | typeof FETCH_ASSESSMENT_PREVIEWS_FAIL
  | typeof RESET_PUBLIC_ASSESSMENT
  | typeof CREATE_ASSESSMENT_START
  | typeof CREATE_ASSESSMENT_SUCCESS
  | typeof CREATE_ASSESSMENT_FAIL
  | typeof UPDATE_ASSESSMENT_START
  | typeof UPDATE_ASSESSMENT_SUCCESS
  | typeof UPDATE_ASSESSMENT_FAIL
  | typeof DELETE_ASSESSMENT_START
  | typeof DELETE_ASSESSMENT_SUCCESS
  | typeof DELETE_ASSESSMENT_FAIL
  | typeof FETCH_PUBLIC_ASSESSMENT_START
  | typeof FETCH_PUBLIC_ASSESSMENT_SUCCESS
  | typeof FETCH_PUBLIC_ASSESSMENT_FAIL
  | typeof FETCH_ASSESSMENT_CANDIDATES_START
  | typeof FETCH_ASSESSMENT_CANDIDATES_SUCCESS
  | typeof FETCH_ASSESSMENT_CANDIDATES_FAIL
  | typeof RESET_ASSESSMENT_CANDIDATES
  | typeof SET_SAVED_ASSESSMENTS
  | typeof SET_SAVED_PUBLIC_ASSESSMENT_DATA
  | typeof RESET_SAVED_PUBLIC_ASSESSMENT_DATA
  | typeof RESET_ASSESSMENT_STORE
  | typeof LOGOUT;
