import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import {
  setUserSettingStart,
  setUserSettingFail,
  setUserSettingSuccess,
} from './actions';
import { UserSettingType } from '../../domain/UserSetting';

export type SetUserSettingRequest = {
  type: UserSettingType;
  value: string;
};

const API_URL = '/user-settings';

export const setUserSetting =
  (inputs: SetUserSettingRequest) => (dispatch: Dispatch) => {
    dispatch(setUserSettingStart());
    return axios
      .post(API_URL, {
        ...inputs,
      })
      .then((response) => {
        dispatch(setUserSettingSuccess(response.data));
      })
      .catch((err) => {
        dispatch(setUserSettingFail(err?.response?.data?.message));
      });
  };
