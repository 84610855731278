import { User } from './User';

export interface Payment {
  id: number;
  transactionId: string;
  amount: number;
  isPaid: boolean;
  user: User;
  createdAt: Date;
}

export enum PurchaseStatus {
  // eslint-disable-next-line no-unused-vars
  PAID = 'PAID',
  // eslint-disable-next-line no-unused-vars
  PENDING = 'PENDING',
  // eslint-disable-next-line no-unused-vars
  CANCELLED = 'CANCELLED',
}

export enum PaymentType {
  // eslint-disable-next-line no-unused-vars
  USER_ASSESSMENT = 'USER_ASSESSMENT',
  // eslint-disable-next-line no-unused-vars
  TESTS_TOP_UP = 'TESTS_TOP_UP',
}

export enum SubscriptionType {
  // eslint-disable-next-line no-unused-vars
  TRIAL = 'TRIAL',
  // eslint-disable-next-line no-unused-vars
  DETAILED = 'DETAILED',
  // eslint-disable-next-line no-unused-vars
  MEDIUM = 'MEDIUM',
  // eslint-disable-next-line no-unused-vars
  UNLIMITED = 'UNLIMITED',
}
