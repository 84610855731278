import React from 'react';
import styles from './FooterCopyright.module.scss';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import ababaTechLogo from '../../../assets/ababa-tech-logo.svg';

const FooterCopyright = () => {
  const intl = useIntl();

  return (
    <div className={styles.footerCopyright}>
      <p>{translate(intl, 'FOOTER.COPYRIGHT')}</p>

      <div className={styles.logoContainer}>
        <p>{translate(intl, 'FOOTER.POWERED_BY')}</p>

        <div>
          <a
            href="https://ababa.tech/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ababaTechLogo} alt="ababa.tech logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterCopyright;
