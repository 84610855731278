import * as actionTypes from './actionTypes';
import { EmployeeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Employee } from '../../domain/Employee';

export type EmployeeStateType = {
  employeesList: ListResults<Employee> | null;
  employeesListLoading: boolean;
  employeesListError: HttpError;
  employeesListUpdateNeeded: boolean;
  employeeCreateLoading: boolean;
  employeeCreateError: HttpError;
  employeeCreateSuccess: boolean;
  createdEmployee: Employee | null;
  employeeUpdateLoading: boolean;
  employeeUpdateError: HttpError;
  employeeUpdateSuccess: boolean;
  employee: Employee | null;
  employeeLoading: boolean;
  employeeError: HttpError;
  employeeDeleteLoading: boolean;
  employeeDeleteError: HttpError;
  employeeDeleteSuccess: boolean;
};

export type EmployeeActionType = EmployeeStateType & {
  type: EmployeeActionTypes;
};

export const initialState: EmployeeStateType = {
  employeesList: null,
  employeesListLoading: true,
  employeesListError: null,
  employeesListUpdateNeeded: false,
  employeeCreateLoading: false,
  employeeCreateError: null,
  employeeCreateSuccess: false,
  createdEmployee: null,
  employeeUpdateLoading: false,
  employeeUpdateError: null,
  employeeUpdateSuccess: false,
  employee: null,
  employeeLoading: false,
  employeeError: null,
  employeeDeleteLoading: false,
  employeeDeleteError: null,
  employeeDeleteSuccess: false,
};

const fetchEmployeesListStart = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeesListLoading: true,
});

const fetchEmployeesListSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesListLoading: false,
  employeesList: action.employeesList,
});

const fetchEmployeesListFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesListError: action.employeesListError,
  employeesListLoading: false,
});

const fetchEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeLoading: true,
});

const fetchEmployeeSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employee: action.employee,
  employeeLoading: false,
});

const fetchEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesListError: action.employeesListError,
  employeeLoading: false,
});

const createEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: true,
});

const createEmployeeSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: false,
  employeeCreateSuccess: true,
  createdEmployee: action.createdEmployee,
  employeesListUpdateNeeded: true,
});

const createEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: false,
  employeeCreateError: action.employeeCreateError,
});

const updateEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: true,
});

const updateEmployeeSuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: false,
  employeeUpdateSuccess: true,
  employeesListUpdateNeeded: true,
});

const updateEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: false,
  employeeUpdateError: action.employeeUpdateError,
});

const employeeDeleteLoading = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: true,
});

const employeeDeleteSuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: false,
  employeeDeleteSuccess: true,
  employeesListUpdateNeeded: true,
});

const employeeDeleteError = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: false,
  employeeDeleteError: action.employeeDeleteError,
});

const resetCreatedEmployee = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  createdEmployee: null,
  employeeCreateLoading: false,
  employeeCreateSuccess: false,
  employeeCreateError: null,
  employeesListUpdateNeeded: false,
});

const resetUpdatedEmployee = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employee: null,
  employeeLoading: false,
  employeeError: null,
  employeeUpdateLoading: false,
  employeeUpdateSuccess: false,
  employeeUpdateError: null,
  employeesListUpdateNeeded: false,
});

const resetEmployeeStore = (): EmployeeStateType => ({
  ...initialState,
});

const completelyResetEmployeeStore = (): EmployeeStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EmployeeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYEES_LIST_START:
      return fetchEmployeesListStart(state);
    case actionTypes.FETCH_EMPLOYEES_LIST_SUCCESS:
      return fetchEmployeesListSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEES_LIST_FAIL:
      return fetchEmployeesListFail(state, action);
    case actionTypes.FETCH_EMPLOYEE_START:
      return fetchEmployeeStart(state);
    case actionTypes.FETCH_EMPLOYEE_SUCCESS:
      return fetchEmployeeSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEE_FAIL:
      return fetchEmployeeFail(state, action);
    case actionTypes.CREATE_EMPLOYEE_START:
      return createEmployeeStart(state);
    case actionTypes.CREATE_EMPLOYEE_SUCCESS:
      return createEmployeeSuccess(state, action);
    case actionTypes.CREATE_EMPLOYEE_FAIL:
      return createEmployeeFail(state, action);
    case actionTypes.UPDATE_EMPLOYEE_START:
      return updateEmployeeStart(state);
    case actionTypes.UPDATE_EMPLOYEE_SUCCESS:
      return updateEmployeeSuccess(state);
    case actionTypes.UPDATE_EMPLOYEE_FAIL:
      return updateEmployeeFail(state, action);
    case actionTypes.DELETE_EMPLOYEE_START:
      return employeeDeleteLoading(state);
    case actionTypes.DELETE_EMPLOYEE_SUCCESS:
      return employeeDeleteSuccess(state);
    case actionTypes.DELETE_EMPLOYEE_FAIL:
      return employeeDeleteError(state, action);
    case actionTypes.RESET_EMPLOYEE_STORE:
      return resetEmployeeStore();
    case actionTypes.RESET_UPDATED_EMPLOYEE:
      return resetUpdatedEmployee(state);
    case actionTypes.RESET_CREATED_EMPLOYEE:
      return resetCreatedEmployee(state);
    case actionTypes.LOGOUT:
      return completelyResetEmployeeStore();
    default:
      return state;
  }
};

export default reducer;
