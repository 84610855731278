import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './LoginForm.module.scss';
import Alert from '../../../../common/Alert/Alert';
import { useForm } from '../../../../hooks/useForm/useForm';
import { HttpError } from '../../../../config/Axios/axios-instance';
import Form from '../../../../common/Form/Form';
import * as authService from '../../../../store/auth/service';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import Button from '../../../../common/Button/Button';
import { useIntl } from 'react-intl';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { translate } from '../../../../utility/messageTranslator/translate';
import { LoginRequest } from '../../../../store/auth/service';
import TextField from '../../../../common/TextField/TextField';
import { SignUpSectionType } from '../../../../pages/Public/SignUpPage/SignUpPage';

type Props = {
  onLogin: (inputs: LoginRequest) => void;
  isLoading: boolean;
  error: HttpError;
  onChangeSection: (section: SignUpSectionType) => void;
};

type FormInputs = {
  email: string;
  password: string;
};

export const LoginForm = ({
  onLogin,
  isLoading,
  error,
  onChangeSection,
}: Props) => {
  const intl = useIntl();

  const INPUTS = [
    {
      name: 'email',
      label: translate(intl, 'LOGIN_FORM.EMAIL'),
      type: 'text',
      validation: [
        {
          type: 'required',
        },
        {
          type: 'email',
        },
        {
          type: 'minLength',
          parameter: 4,
        },
        {
          type: 'maxLength',
          parameter: 60,
        },
      ],
    },
    {
      name: 'password',
      label: translate(intl, 'LOGIN_FORM.PASSWORD'),
      type: 'password',
      forgotPassword: {
        label: 'LOGIN_FORM.FORGOT_PASSWORD',
        section: SignUpSectionType.PASSWORD_REMIND,
      },
      validation: [
        {
          type: 'required',
        },
        {
          type: 'minLength',
          parameter: 8,
        },
        {
          type: 'maxLength',
          parameter: 30,
        },
      ],
    },
  ];

  const handleSubmit = async (submitInputs: FormInputs) => {
    onLogin({
      email: submitInputs.email,
      password: submitInputs.password,
    });
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSetValidationErrors,
    onInputBlur,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  const globalError = getGlobalError(error, intl);

  return (
    <div className={styles.loginForm}>
      <Form
        className={styles.form}
        error={error}
        onSubmit={onSubmit}
        scrollIntoView={false}
      >
        {globalError && (
          <Alert variant="danger" capitalize={false}>
            {translate(intl, globalError)}
          </Alert>
        )}
        {inputs.map((input) => (
          <TextField
            className={styles.formInput}
            key={input.name}
            onChange={onInputChange}
            value={input.value?.toString() ?? ''}
            label={input.label ?? ''}
            onBlur={onLoseInputFocus}
            name={input.name}
            type={input.type}
            onInputBlur={onInputBlur}
            errors={input.validationErrors ?? []}
            forgotPassword={input.forgotPassword}
            onChangeSection={onChangeSection}
            inputLabelProps={{
              shrink: true,
              sx: {
                marginTop: '-1rem',
              },
            }}
          />
        ))}
        <Button
          isLoadingButton
          onClick={onSubmit}
          buttonVariant="contained"
          color="primary"
          type="submit"
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          {translate(intl, 'LOGIN_FORM.SUBMIT')}
        </Button>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.auth.loginError,
  isLoading: state.auth.loginLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogin: (inputs: LoginRequest) => dispatch(authService.login(inputs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
