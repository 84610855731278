import * as actionTypes from './actionTypes';
import { PaymentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Payment } from '../../domain/Payment';

export type PaymentStateType = {
  paymentsList: ListResults<Payment> | null;
  paymentsLoading: boolean;
  paymentsError: HttpError;
  paymentCreateLoading: boolean;
  paymentCreateError: HttpError;
  redirectUrl: string | null;
};

export type PaymentActionType = PaymentStateType & {
  type: PaymentActionTypes;
};

export const initialState: PaymentStateType = {
  paymentsList: null,
  paymentsLoading: true,
  paymentsError: null,
  paymentCreateLoading: false,
  paymentCreateError: null,
  redirectUrl: null,
};

const fetchPaymentsStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentsLoading: true,
});

const fetchPaymentsSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentsList: action.paymentsList,
  paymentsLoading: false,
  paymentsError: null,
});

const fetchPaymentsFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentsError: action.paymentsError,
  paymentsLoading: false,
});

const createPaymentStart = (state: PaymentStateType): PaymentStateType => ({
  ...state,
  paymentCreateLoading: true,
});

const createPaymentSuccess = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentCreateLoading: false,
  paymentCreateError: null,
  redirectUrl: action.redirectUrl,
});

const createPaymentFail = (
  state: PaymentStateType,
  action: PaymentActionType,
): PaymentStateType => ({
  ...state,
  paymentCreateLoading: false,
  paymentCreateError: action.paymentCreateError,
});

const resetPaymentStore = (): PaymentStateType => ({
  ...initialState,
});

const logout = (): PaymentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: PaymentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENTS_START:
      return fetchPaymentsStart(state);
    case actionTypes.FETCH_PAYMENTS_SUCCESS:
      return fetchPaymentsSuccess(state, action);
    case actionTypes.FETCH_PAYMENTS_FAIL:
      return fetchPaymentsFail(state, action);
    case actionTypes.CREATE_PAYMENT_START:
      return createPaymentStart(state);
    case actionTypes.CREATE_PAYMENT_SUCCESS:
      return createPaymentSuccess(state, action);
    case actionTypes.CREATE_PAYMENT_FAIL:
      return createPaymentFail(state, action);
    case actionTypes.RESET_PAYMENT_STORE:
      return resetPaymentStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
