export const FETCH_ANSWERS_START = 'FETCH_ANSWERS_START';
export const FETCH_ANSWERS_SUCCESS = 'FETCH_ANSWERS_SUCCESS';
export const FETCH_ANSWERS_FAIL = 'FETCH_ANSWERS_FAIL';

export const CREATE_ANSWER_START = 'CREATE_ANSWER_START';
export const CREATE_ANSWER_SUCCESS = 'CREATE_ANSWER_SUCCESS';
export const CREATE_ANSWER_FAIL = 'CREATE_ANSWER_FAIL';

export const UPDATE_ANSWER_START = 'UPDATE_ANSWER_START';
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS';
export const UPDATE_ANSWER_FAIL = 'UPDATE_ANSWER_FAIL';

export const DELETE_ANSWER_START = 'DELETE_ANSWER_START';
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS';
export const DELETE_ANSWER_FAIL = 'DELETE_ANSWER_FAIL';

export const RESET_CREATED_ANSWER = 'RESET_CREATED_ANSWER';
export const RESET_UPDATED_ANSWER = 'RESET_UPDATED_ANSWER';
export const RESET_ANSWER_STORE = 'RESET_ANSWER_STORE';

export const LOGOUT = 'LOGOUT';

export type AnswerActionTypes =
  | typeof FETCH_ANSWERS_START
  | typeof FETCH_ANSWERS_SUCCESS
  | typeof FETCH_ANSWERS_FAIL
  | typeof CREATE_ANSWER_START
  | typeof CREATE_ANSWER_SUCCESS
  | typeof CREATE_ANSWER_FAIL
  | typeof UPDATE_ANSWER_START
  | typeof UPDATE_ANSWER_SUCCESS
  | typeof UPDATE_ANSWER_FAIL
  | typeof DELETE_ANSWER_START
  | typeof DELETE_ANSWER_SUCCESS
  | typeof DELETE_ANSWER_FAIL
  | typeof RESET_CREATED_ANSWER
  | typeof RESET_UPDATED_ANSWER
  | typeof RESET_ANSWER_STORE
  | typeof LOGOUT;
