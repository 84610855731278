import React, { useState } from 'react';
import AuthContainer from '../../../component/Public/Auth/AuthContainer/AuthContainer';
import SignUpSection from '../../../component/Public/Auth/SignUpSection/SignUpSection';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';

export enum SignUpSectionType {
  // eslint-disable-next-line no-unused-vars
  LOGIN = 'LOGIN',
  // eslint-disable-next-line no-unused-vars
  REGISTER = 'REGISTER',
  // eslint-disable-next-line no-unused-vars
  PASSWORD_REMIND = 'PASSWORD_REMIND',
}

type Props = {
  sectionType?: SignUpSectionType;
};

const SignUpPage = ({ sectionType }: Props) => {
  const intl = useIntl();
  const [section, setSection] = useState(
    sectionType ? sectionType : SignUpSectionType.LOGIN,
  );

  const getLabel = () => {
    if (section === SignUpSectionType.REGISTER) {
      return translate(intl, 'SIGN_UP_PAGE.REGISTRATION_SUBTITLE');
    }

    return translate(intl, 'SIGN_UP_PAGE.SUBTITLE');
  };

  const getTitle = () => {
    if (section === SignUpSectionType.REGISTER) {
      return translate(intl, 'SIGN_UP_PAGE.REGISTRATION_TITLE_MAIN');
    }

    return translate(intl, 'SIGN_UP_PAGE.TITLE_MAIN');
  };

  return (
    <AuthContainer
      title={getTitle()}
      subTitle={getLabel()}
      onChangeSection={setSection}
      section={section}
    >
      <SignUpSection section={section} onChangeSection={setSection} />
    </AuthContainer>
  );
};

export default SignUpPage;
