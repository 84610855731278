import * as actionTypes from './actionTypes';
import { TestActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Test } from '../../domain/Test';

export type TestStateType = {
  testsList: ListResults<Test> | null;
  testsListLoading: boolean;
  testsListError: HttpError;
  testsListUpdateNeeded: boolean;
  testCreateLoading: boolean;
  testCreateError: HttpError;
  testCreateSuccess: boolean;
  createdTest: Test | null;
  testUpdateLoading: boolean;
  testUpdateError: HttpError;
  testUpdateSuccess: boolean;
  test: Test | null;
  testLoading: boolean;
  testError: HttpError;
  testDeleteLoading: boolean;
  testDeleteError: HttpError;
  testDeleteSuccess: boolean;
};

export type TestActionType = TestStateType & {
  type: TestActionTypes;
};

export const initialState: TestStateType = {
  testsList: null,
  testsListLoading: true,
  testsListError: null,
  testsListUpdateNeeded: false,
  testCreateLoading: false,
  testCreateError: null,
  testCreateSuccess: false,
  createdTest: null,
  testUpdateLoading: false,
  testUpdateError: null,
  testUpdateSuccess: false,
  test: null,
  testLoading: false,
  testError: null,
  testDeleteLoading: false,
  testDeleteError: null,
  testDeleteSuccess: false,
};

const fetchTestsListStart = (state: TestStateType): TestStateType => ({
  ...state,
  testsListLoading: true,
});

const fetchTestsListSuccess = (
  state: TestStateType,
  action: TestActionType,
): TestStateType => ({
  ...state,
  testsListLoading: false,
  testsList: action.testsList,
  testsListUpdateNeeded: false,
});

const fetchTestsListFail = (
  state: TestStateType,
  action: TestActionType,
): TestStateType => ({
  ...state,
  testsListLoading: false,
  testsListError: action.testsListError,
});

const fetchTestStart = (state: TestStateType): TestStateType => ({
  ...state,
  testLoading: true,
});

const fetchTestSuccess = (
  state: TestStateType,
  action: TestActionType,
): TestStateType => ({
  ...state,
  testLoading: false,
  test: action.test,
});

const fetchTestFail = (
  state: TestStateType,
  action: TestActionType,
): TestStateType => ({
  ...state,
  testLoading: false,
  testError: action.testError,
});

const createTestStart = (state: TestStateType): TestStateType => ({
  ...state,
  testCreateLoading: true,
});

const createTestSuccess = (
  state: TestStateType,
  action: TestActionType,
): TestStateType => ({
  ...state,
  testCreateLoading: false,
  testCreateError: null,
  testCreateSuccess: true,
  testsListUpdateNeeded: true,
  createdTest: action.createdTest,
  testsList: state.testsList
    ? {
        total: state.testsList.total + 1,
        result: [...state.testsList.result, action.createdTest] as Test[],
      }
    : null,
});

const createTestFail = (
  state: TestStateType,
  action: TestActionType,
): TestStateType => ({
  ...state,
  testCreateLoading: false,
  testCreateError: action.testCreateError,
});

const updateTestStart = (state: TestStateType): TestStateType => ({
  ...state,
  testUpdateLoading: true,
});

const updateTestSuccess = (state: TestStateType): TestStateType => ({
  ...state,
  testUpdateLoading: false,
  testUpdateSuccess: true,
  testsListUpdateNeeded: true,
});

const updateTestFail = (
  state: TestStateType,
  action: TestActionType,
): TestStateType => ({
  ...state,
  testUpdateLoading: false,
  testUpdateError: action.testUpdateError,
});

const deleteTestStart = (state: TestStateType): TestStateType => ({
  ...state,
  testDeleteLoading: true,
});

const deleteTestSuccess = (state: TestStateType): TestStateType => ({
  ...state,
  testDeleteLoading: false,
  testDeleteSuccess: true,
  testsListUpdateNeeded: true,
});

const deleteTestFail = (
  state: TestStateType,
  action: TestActionType,
): TestStateType => ({
  ...state,
  testDeleteLoading: false,
  testDeleteError: action.testDeleteError,
});

const resetCreatedTestStore = (state: TestStateType): TestStateType => ({
  ...state,
  createdTest: null,
});

const resetTestStore = (): TestStateType => ({
  ...initialState,
});

const completelyResetTestStore = (): TestStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TestActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TESTS_LIST_START:
      return fetchTestsListStart(state);
    case actionTypes.FETCH_TESTS_LIST_SUCCESS:
      return fetchTestsListSuccess(state, action);
    case actionTypes.FETCH_TESTS_LIST_FAIL:
      return fetchTestsListFail(state, action);
    case actionTypes.FETCH_TEST_START:
      return fetchTestStart(state);
    case actionTypes.FETCH_TEST_SUCCESS:
      return fetchTestSuccess(state, action);
    case actionTypes.FETCH_TEST_FAIL:
      return fetchTestFail(state, action);
    case actionTypes.CREATE_TEST_START:
      return createTestStart(state);
    case actionTypes.CREATE_TEST_SUCCESS:
      return createTestSuccess(state, action);
    case actionTypes.CREATE_TEST_FAIL:
      return createTestFail(state, action);
    case actionTypes.UPDATE_TEST_START:
      return updateTestStart(state);
    case actionTypes.UPDATE_TEST_SUCCESS:
      return updateTestSuccess(state);
    case actionTypes.UPDATE_TEST_FAIL:
      return updateTestFail(state, action);
    case actionTypes.DELETE_TEST_START:
      return deleteTestStart(state);
    case actionTypes.DELETE_TEST_SUCCESS:
      return deleteTestSuccess(state);
    case actionTypes.DELETE_TEST_FAIL:
      return deleteTestFail(state, action);
    case actionTypes.RESET_TEST_STORE:
      return resetTestStore();
    case actionTypes.RESET_CREATED_TEST_STORE:
      return resetCreatedTestStore(state);
    case actionTypes.LOGOUT:
      return completelyResetTestStore();
    default:
      return state;
  }
};

export default reducer;
