export const FETCH_QUESTIONS_START = 'FETCH_QUESTIONS_START';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
export const FETCH_QUESTIONS_FAIL = 'FETCH_QUESTIONS_FAIL';

export const FETCH_QUESTION_START = 'FETCH_QUESTION_START';
export const FETCH_QUESTION_SUCCESS = 'FETCH_QUESTION_SUCCESS';
export const FETCH_QUESTION_FAIL = 'FETCH_QUESTION_FAIL';

export const CREATE_QUESTION_START = 'CREATE_QUESTION_START';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAIL = 'CREATE_QUESTION_FAIL';

export const CREATE_KYC_QUESTION_START = 'CREATE_KYC_QUESTION_START';
export const CREATE_KYC_QUESTION_SUCCESS = 'CREATE_KYC_QUESTION_SUCCESS';
export const CREATE_KYC_QUESTION_FAIL = 'CREATE_KYC_QUESTION_FAIL';

export const UPDATE_QUESTION_START = 'UPDATE_QUESTION_START';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAIL = 'UPDATE_QUESTION_FAIL';

export const UPDATE_KYC_QUESTION_START = 'UPDATE_KYC_QUESTION_START';
export const UPDATE_KYC_QUESTION_SUCCESS = 'UPDATE_KYC_QUESTION_SUCCESS';
export const UPDATE_KYC_QUESTION_FAIL = 'UPDATE_KYC_QUESTION_FAIL';

export const DELETE_QUESTION_START = 'DELETE_QUESTION_START';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL';

export const FETCH_TEST_QUESTION_START = 'FETCH_TEST_QUESTION_START';
export const FETCH_TEST_QUESTION_SUCCESS = 'FETCH_TEST_QUESTION_SUCCESS';
export const FETCH_TEST_QUESTION_FAIL = 'FETCH_TEST_QUESTION_FAIL';
export const RESET_TEST_QUESTION = 'RESET_TEST_QUESTION';

export const RESET_QUESTION_STORE = 'RESET_QUESTION_STORE';
export const LOGOUT = 'LOGOUT';

export type QuestionActionTypes =
  | typeof FETCH_QUESTIONS_START
  | typeof FETCH_QUESTIONS_SUCCESS
  | typeof FETCH_QUESTIONS_FAIL
  | typeof FETCH_QUESTION_START
  | typeof FETCH_QUESTION_SUCCESS
  | typeof FETCH_QUESTION_FAIL
  | typeof CREATE_QUESTION_START
  | typeof CREATE_QUESTION_SUCCESS
  | typeof CREATE_QUESTION_FAIL
  | typeof CREATE_KYC_QUESTION_START
  | typeof CREATE_KYC_QUESTION_SUCCESS
  | typeof CREATE_KYC_QUESTION_FAIL
  | typeof UPDATE_QUESTION_START
  | typeof UPDATE_QUESTION_SUCCESS
  | typeof UPDATE_QUESTION_FAIL
  | typeof UPDATE_KYC_QUESTION_START
  | typeof UPDATE_KYC_QUESTION_SUCCESS
  | typeof UPDATE_KYC_QUESTION_FAIL
  | typeof DELETE_QUESTION_START
  | typeof DELETE_QUESTION_SUCCESS
  | typeof DELETE_QUESTION_FAIL
  | typeof FETCH_TEST_QUESTION_START
  | typeof FETCH_TEST_QUESTION_SUCCESS
  | typeof FETCH_TEST_QUESTION_FAIL
  | typeof RESET_TEST_QUESTION
  | typeof RESET_QUESTION_STORE
  | typeof LOGOUT;
