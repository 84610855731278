export interface UserSetting {
  id: number;
  type: UserSettingType;
  value: string;
}

export enum UserSettingType {
  // eslint-disable-next-line no-unused-vars
  COMPANY = 'COMPANY',
}
