import * as actionTypes from './actionTypes';
import { AssessmentReviewActionTypes } from './actionTypes';
import { AssessmentReview } from '../../domain/AssessmentReview';
import { ListResults } from '../../common/List/List';
import { HttpError } from '../../config/Axios/axios-instance';

export type AssessmentReviewStateType = {
  assessmentReviews: ListResults<AssessmentReview> | null;
  assessmentReviewsLoading: boolean;
  assessmentReviewsError: HttpError;
};

export type AssessmentReviewActionType = AssessmentReviewStateType & {
  type: AssessmentReviewActionTypes;
};

export const initialState: AssessmentReviewStateType = {
  assessmentReviews: null,
  assessmentReviewsLoading: true,
  assessmentReviewsError: null,
};

const fetchAssessmentReviewsStart = (
  state: AssessmentReviewStateType,
): AssessmentReviewStateType => ({
  ...state,
  assessmentReviewsLoading: true,
});

const fetchAssessmentReviewsSuccess = (
  state: AssessmentReviewStateType,
  action: AssessmentReviewActionType,
): AssessmentReviewStateType => ({
  ...state,
  assessmentReviews: action.assessmentReviews,
  assessmentReviewsLoading: false,
  assessmentReviewsError: null,
});

const fetchAssessmentReviewsFail = (
  state: AssessmentReviewStateType,
  action: AssessmentReviewActionType,
): AssessmentReviewStateType => ({
  ...state,
  assessmentReviewsError: action.assessmentReviewsError,
  assessmentReviewsLoading: false,
});

const resetAssessmentReviewsStore = (): AssessmentReviewStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: AssessmentReviewActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSESSMENT_REVIEWS_START:
      return fetchAssessmentReviewsStart(state);
    case actionTypes.FETCH_ASSESSMENT_REVIEWS_SUCCESS:
      return fetchAssessmentReviewsSuccess(state, action);
    case actionTypes.FETCH_ASSESSMENT_REVIEWS_FAIL:
      return fetchAssessmentReviewsFail(state, action);
    case actionTypes.RESET_ASSESSMENT_REVIEWS_STORE:
      return resetAssessmentReviewsStore();
    default:
      return state;
  }
};

export default reducer;
