export const FETCH_PAYMENTS_START = 'FETCH_PAYMENTS_START';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAIL = 'FETCH_PAYMENTS_FAIL';

export const CREATE_PAYMENT_START = 'CREATE_PAYMENT_START';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAIL = 'CREATE_PAYMENT_FAIL';

export const RESET_PAYMENT_STORE = 'RESET_PAYMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type PaymentActionTypes =
  | typeof FETCH_PAYMENTS_START
  | typeof FETCH_PAYMENTS_SUCCESS
  | typeof FETCH_PAYMENTS_FAIL
  | typeof CREATE_PAYMENT_START
  | typeof CREATE_PAYMENT_SUCCESS
  | typeof CREATE_PAYMENT_FAIL
  | typeof RESET_PAYMENT_STORE
  | typeof LOGOUT;
