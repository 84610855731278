import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './CommonLinks.module.scss';
import cx from 'classnames';
import { routes } from '../../../config/Router/routes';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../common/Button/Button';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../../store/auth/service';
import { User } from '../../../domain/User';
import TestPurchaseModal from '../../../component/Public/Payment/TestsPurchaseModal/TestPurchaseModal';
import { Chip } from '@mui/material';
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher';

export type Props = {
  isAuthenticated: boolean;
  onLogout: () => void;
  currentUser: User | null;
};
export interface LinkItem {
  label: string;
  to: string;
}

const CommonLinks = ({ isAuthenticated, onLogout, currentUser }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);

  const PUBLIC_COMMON_LINKS: LinkItem[] = [
    {
      label: translate(intl, 'PUBLIC_COMMON_LINK.TESTS'),
      to: routes.publicAssessments,
    },
    {
      label: translate(intl, 'PUBLIC_COMMON_LINK.HOME'),
      to: routes.cvGorilla,
    },
  ];

  return (
    <div className={styles.navLinkContainer}>
      {PUBLIC_COMMON_LINKS.map(({ label, to }: LinkItem) => (
        <NavLink
          key={label}
          to={to}
          className={({ isActive }) =>
            cx(styles.navLink, {
              [styles.activeNavLink]: isActive,
            })
          }
        >
          {label}
        </NavLink>
      ))}
      <div className={styles.buttonsContainer}>
        {isAuthenticated && currentUser && (
          <>
            <Chip
              className={styles.creditAmountChip}
              label={currentUser.profile?.remainingTests}
            />
            <Button
              className={styles.navigationButton}
              type="button"
              onClick={() => setIsPurchaseModalOpen(true)}
            >
              {translate(intl, 'PUBLIC_COMMON_LINK.ADD_CREDITS')}
            </Button>
          </>
        )}
        <Button
          className={styles.navigationButton}
          type="button"
          onClick={() =>
            navigate(isAuthenticated ? routes.profile : routes.login)
          }
        >
          {translate(
            intl,
            isAuthenticated
              ? 'PUBLIC_COMMON_LINK.PROFILE'
              : 'PUBLIC_COMMON_LINK.TRY_OUT',
          )}
        </Button>
        {isAuthenticated && (
          <Button
            className={styles.navigationButton}
            type="button"
            onClick={() => onLogout()}
            buttonVariant="outlined"
          >
            {translate(intl, 'PUBLIC_COMMON_LINK.LOGOUT')}
          </Button>
        )}
      </div>
      <TestPurchaseModal
        isOpen={isPurchaseModalOpen}
        onClose={() => setIsPurchaseModalOpen(false)}
      />
      <LanguageSwitcher />
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonLinks);
