import React from 'react';
import styles from './FooterNavLinkBox.module.scss';
import { LinkItem } from '../../PublicNavigation/CommonLinks/CommonLinks';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { routes } from '../../../config/Router/routes';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { ReactSVG } from 'react-svg';
import locationLogo from '../../../assets/icons/location.svg';
import phoneLogo from '../../../assets/icons/phone.svg';
import mailLogo from '../../../assets/icons/mail.svg';

type Props = {
  index: number;
};

const FooterNavLinkBox = ({ index }: Props) => {
  const intl = useIntl();

  const serviceLinks: LinkItem[] = [
    { label: translate(intl, 'FOOTER.TESTS'), to: routes.publicAssessments },
  ];

  const informationLinks: LinkItem[] = [
    {
      label: translate(intl, 'FOOTER.PRIVACY_POLICY'),
      to: routes.privacyPolicy,
    },
  ];

  const getTitle = (index: number) => {
    if (index === 2) {
      return translate(intl, 'FOOTER.INFORMATION');
    }

    if (index === 3) {
      return translate(intl, 'FOOTER.CONTACTS');
    }

    return translate(intl, 'FOOTER.SERVICES');
  };

  const getLinksOrContent = (index: number) => {
    if (index === 2) {
      return informationLinks.map(({ label, to }: LinkItem) => (
        <NavLink
          key={label}
          to={to}
          className={({ isActive }) =>
            cx(styles.navLink, {
              [styles.activeNavLink]: isActive,
            })
          }
        >
          {label}
        </NavLink>
      ));
    }

    if (index === 3) {
      return (
        <>
          <div className={styles.footerContactsIconAndNameContainer}>
            <ReactSVG src={locationLogo} />
            <p>{translate(intl, 'FOOTER.LOCATION')}</p>
          </div>

          <div className={styles.footerContactsIconAndNameContainer}>
            <ReactSVG src={phoneLogo} />
            <a href={`tel:${translate(intl, 'FOOTER.PHONE_NUMBER')}`}>
              {translate(intl, 'FOOTER.PHONE_NUMBER')}
            </a>
          </div>

          <div className={styles.footerContactsIconAndNameContainer}>
            <ReactSVG src={mailLogo} />
            <a href={`mailto:${translate(intl, 'FOOTER.EMAIL')}`}>
              {translate(intl, 'FOOTER.EMAIL')}
            </a>
          </div>
        </>
      );
    }

    return serviceLinks.map(({ label, to }: LinkItem) => (
      <NavLink
        key={label}
        to={to}
        className={({ isActive }) =>
          cx(styles.navLink, {
            [styles.activeNavLink]: isActive,
          })
        }
      >
        {label}
      </NavLink>
    ));
  };

  return (
    <div className={styles.footerNavLinkBoxContainer}>
      <p className={styles.footerNavLinkBoxTitle}>{getTitle(index)}</p>

      <div className={styles.navLinkContainer}>{getLinksOrContent(index)}</div>
    </div>
  );
};

export default FooterNavLinkBox;
