import React, { ChangeEvent, useRef } from 'react';
import styles from './ProfileImageInput.module.scss';

type Props = {
  name: string;
  value: File | File[] | string | string[] | undefined;
  onFileChange?: (event: ChangeEvent<any>) => void;
  errors?: Array<string>;
};

const ProfileImageInput = ({ onFileChange, value, name, errors }: Props) => {
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadCLick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const renderImage = (value: File | string, index: number) => {
    return (
      <div key={`image-${index}`} className={styles.imageContainer}>
        {value instanceof File ? (
          <img src={URL.createObjectURL(value)} alt={value.name} />
        ) : (
          <img src={value.toString()} alt={value?.toString() || ''} />
        )}
      </div>
    );
  };

  return (
    <>
      <div className={styles.fileFieldContainer}>
        <div className={styles.fileField}>
          <input
            color="primary"
            type="file"
            onChange={onFileChange}
            ref={uploadInputRef}
            name={name}
          />
          <div className={styles.fileContainer}>
            <div
              className={styles.fileSelectedContainer}
              onClick={handleUploadCLick}
            >
              {value && renderImage(value as File | string, 0)}
            </div>
          </div>
        </div>
      </div>
      {errors &&
        errors.map((error, index) => (
          <div className={styles.fileError} key={`file-error-${index}`}>
            {error}
          </div>
        ))}
    </>
  );
};

export default ProfileImageInput;
