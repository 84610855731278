import React, { ReactNode } from 'react';
import styles from './FormCard.module.scss';
import cx from 'classnames';

type Props = {
  children: ReactNode;
  withShadow?: boolean;
};

export const FormCard = ({ children, withShadow = true }: Props) => {
  return (
    <div className={cx(styles.formCard, { [styles.withShadow]: withShadow })}>
      {children}
    </div>
  );
};

export default FormCard;
