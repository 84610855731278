import React, { ReactNode, useState, useEffect } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import {
  faUsers,
  faGlobe,
  faBuilding,
  faTag,
  faLayerGroup,
  faQuestion,
  faFileCircleCheck,
  faFolderTree,
  faBullseye,
  faLanguage,
  faComments,
} from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../config/Router/routes';
import { IntlShape, useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import PublicLayout from './PublicLayout/PublicLayout';
import CurrentAccount from '../CurrentAccount/CurrentAccount';
import { Company } from '../../domain/Company';
import Modal from '../Modal/Modal';
import CompanySelectForm from '../../component/Admin/Company/CompanySelectForm/CompanySelectForm';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as companyService from '../../store/company/service';
import * as userSettingService from '../../store/user-setting/service';
import { setSelectedCompany } from '../../store/company/actions';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';
import { setPostLoginUrl } from '../../store/auth/actions';

export type Props = {
  children: ReactNode;
  currentUser: User | null;
  companyOptions: Company[];
  selectedCompany: number | null;
  onCompanyOptionsFetch: (intl: IntlShape) => void;
  onCompanySelect: (companyId: number) => void;
  isAuthenticated: boolean;
  createdUserSetting: UserSetting | null;
  onSetSetting: (type: UserSettingType, value: string) => void;
  onSetPostLoginUrl: () => void;
  postLoginUrl: string | null;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  currentUser,
  companyOptions,
  selectedCompany,
  onCompanyOptionsFetch,
  onCompanySelect,
  isAuthenticated,
  createdUserSetting,
  onSetSetting,
  onSetPostLoginUrl,
  postLoginUrl,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAutoSelectLoading, setIsAutoSelectLoading] =
    useState<boolean>(false);
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();

  const { width } = useWindowSize();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.key]);

  useEffect(() => {
    if (isAuthenticated && postLoginUrl) {
      navigate(`${postLoginUrl}?loggedIn=true`);
      onSetPostLoginUrl();
    }
  }, [isAuthenticated]);

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'SIDEBAR.MANAGEMENT_SETTINGS'),
      roles: [Roles.ADMIN, Roles.OWNER],
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ASSESSMENTS'),
          to: routes.assessments.list,
          icon: faFileCircleCheck as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TESTS'),
          to: routes.tests.list,
          icon: faLayerGroup as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRAITS'),
          to: routes.traits.list,
          icon: faBullseye as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_QUESTIONS'),
          to: routes.questions.list,
          icon: faQuestion as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_COMPANIES'),
          to: routes.companies.list,
          icon: faBuilding as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CATEGORIES'),
          to: routes.categories.list,
          icon: faFolderTree as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_REVIEWS'),
          to: routes.reviews.list,
          icon: faComments as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: routes.users.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_LANGUAGES'),
          to: routes.languages,
          icon: faLanguage as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (isAuthenticated && currentUser && currentUser.role === Roles.ADMIN) {
      onCompanyOptionsFetch(intl);
    }
  }, []);

  useEffect(() => {
    if (
      createdUserSetting?.type === UserSettingType.COMPANY &&
      isAutoSelectLoading
    ) {
      const companyOption = companyOptions[0];

      onCompanySelect(companyOption.id);

      onCompanySelectSuccess();
      setIsAutoSelectLoading(false);
    }
  }, [createdUserSetting]);

  useEffect(() => {
    if (selectedCompany && companyOptions.length === 0) {
      return;
    }

    if (
      currentUser?.role === Roles.USER &&
      companyOptions.length === 1 &&
      !selectedCompany
    ) {
      const companyOption = companyOptions[0];

      setIsAutoSelectLoading(true);
      onSetSetting(UserSettingType.COMPANY, companyOption.id.toString());
    }

    const company = companyOptions.find(
      (companyOption) => companyOption.id === selectedCompany,
    );

    setCompany(company);
  }, [companyOptions, selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setIsModalOpen(false);
    }
  }, [selectedCompany]);

  const getNavigationGroups = () => {
    if (!companyOptions?.length && currentUser?.role === Roles.USER) {
      return [];
    }

    const sidebarItems = SIDEBAR_ITEMS.map((sidebarItemGroup) => ({
      label: sidebarItemGroup.label,
      items: sidebarItemGroup.items.filter((sidebarItem) => {
        return (
          !!currentUser?.role && sidebarItem.roles.includes(currentUser.role)
        );
      }),
    }));

    if (!company) {
      return sidebarItems;
    }

    return [
      {
        label: translate(intl, 'SIDEBAR.COMPANY_SETTINGS'),
        roles: [Roles.ADMIN, Roles.OWNER],
        items: [
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_JOB_TITLES'),
            to: routes.jobTitles.list,
            icon: faTag as IconProp,
            roles: [Roles.ADMIN, Roles.OWNER],
          },
          {
            label: translate(intl, 'NAVIGATION.SIDEBAR_EMPLOYEES'),
            to: routes.employees.list,
            icon: faUsers as IconProp,
            roles: [Roles.ADMIN, Roles.OWNER],
          },
        ],
      },
      ...sidebarItems,
    ];
  };

  const onCompanySelectSuccess = () => {
    setIsModalOpen(false);

    navigate(routes.companies.list);
  };

  const getCurrentCompany = () => {
    if (currentUser?.role === Roles.USER && companyOptions.length <= 1) {
      return null;
    }

    return (
      <CurrentAccount
        img={company?.logo?.thumbLocation ?? ''}
        title={translate(intl, 'LAYOUT.NO_COMPANY_SELECTED')}
        subTitle={
          company
            ? `${translate(intl, 'LAYOUT.SELECTED_COMPANY')}:`
            : translate(intl, 'LAYOUT.CLICK_TO_SELECT_COMPANY')
        }
        companyName={company?.name}
        onClick={() => !isModalOpen && setIsModalOpen(true)}
      >
        <Modal
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          title={translate(intl, 'LAYOUT.SELECT_COMPANY_TITLE')}
          className={styles.companySelectModal}
        >
          {isModalOpen && (
            <CompanySelectForm onSuccessSelect={onCompanySelectSuccess} />
          )}
        </Modal>
      </CurrentAccount>
    );
  };

  if (!location.pathname.includes(routes.admin)) {
    return <PublicLayout>{children}</PublicLayout>;
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar
            navigationGroups={getNavigationGroups()}
            currentAccount={getCurrentCompany()}
          />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getCurrentCompany()}
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.to}
                  className={({ isActive }) =>
                    cx(styles.navigationItem, {
                      [styles.activeSubItem]: isActive,
                    })
                  }
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  companyOptions: state.company.companyOptions,
  selectedCompany: state.company.selectedCompany,
  isAuthenticated: state.auth.isAuthenticated,
  createdUserSetting: state.userSetting.createdUserSetting,
  postLoginUrl: state.auth.postLoginUrl,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCompanyOptionsFetch: (intl: IntlShape) =>
    dispatch(companyService.fetchCompanyOptions(intl)),
  onCompanySelect: (selectCompanyId: number) =>
    dispatch(setSelectedCompany(selectCompanyId)),
  onSetSetting: (type: UserSettingType, value: string) =>
    dispatch(
      userSettingService.setUserSetting({
        type,
        value,
      }),
    ),
  onSetPostLoginUrl: () => dispatch(setPostLoginUrl(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
