import Router from './config/Router/Router';
import StoreProvider from './config/StoreProvider/StoreProvider';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = () => {
  return (
    <GoogleOAuthProvider clientId="661953350826-fogc8utm2hjqifv2554c5dv7cegqcg23.apps.googleusercontent.com">
      <StoreProvider>
        <Router />
        <ToastContainer />
      </StoreProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
