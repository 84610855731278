import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as companyService from '../../../../store/company/service';
import { IntlShape, useIntl } from 'react-intl';
import { Company } from '../../../../domain/Company';
import { translate } from '../../../../utility/messageTranslator/translate';
import FormCard from '../../../../common/FormCard/FormCard';
import TextField from '../../../../common/TextField/TextField';
import Alert from '../../../../common/Alert/Alert';
import Button from '../../../../common/Button/Button';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import { useForm } from '../../../../hooks/useForm/useForm';
import { HttpError } from '../../../../config/Axios/axios-instance';
import { Locale } from '../../../../domain/Translation';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';
import FormWrapper from '../../../../common/FormWrapper/FormWrapper';
import { UserSetting, UserSettingType } from '../../../../domain/UserSetting';
import { setSelectedCompany } from '../../../../store/company/actions';
import * as userSettingService from '../../../../store/user-setting/service';

type Props = {
  isLoading?: boolean;
  error: HttpError;
  companyOptions: Company[];
  onCompanyFetch: (intl: IntlShape) => void;
  onCompanySelect: (companyId: number) => void;
  selectedCompany: number | null;
  selectedLocale: Locale;
  onSuccessSelect?: () => void;
  onSetSetting: (type: UserSettingType, value: string) => void;
  createdUserSetting: UserSetting | null;
};

type FormInputs = {
  companyId: string;
};

export const CompanySelectForm = ({
  isLoading,
  error,
  companyOptions,
  onCompanyFetch,
  onCompanySelect,
  selectedCompany,
  onSuccessSelect,
  onSetSetting,
  createdUserSetting,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isSelectedLoading, setIsSelectLoading] = useState<boolean>(false);

  useEffect(() => {
    onCompanyFetch(intl);
  }, []);

  const companiesOptions = useMemo(
    () =>
      companyOptions.map((company: Company) => {
        return {
          value: company.id.toString(),
          label: company.name,
        };
      }) || [],
    [companyOptions],
  );

  const INPUTS = [
    {
      name: 'companyId',
      label: translate(intl, 'COMPANY_SELECT_FORM.NAME'),
      type: 'autocomplete',
      validation: [
        {
          type: 'required',
        },
      ],
      options: [],
      value: selectedCompany?.toString() ?? '',
    },
  ];

  useEffect(() => {
    if (
      createdUserSetting?.type === UserSettingType.COMPANY &&
      isSelectedLoading
    ) {
      onCompanySelect(Number(createdUserSetting.value));
      onSuccessSelect?.();
      navigate(routes.companies.list);

      setIsSelectLoading(false);
    }
  }, [createdUserSetting]);

  const handleSubmit = async (submitInputs: FormInputs) => {
    onSetSetting(UserSettingType.COMPANY, submitInputs.companyId);
    setIsSelectLoading(true);
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSelectChange,
    onSetValidationErrors,
    setNewInputObject,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  useEffect(() => {
    if (companiesOptions) {
      setNewInputObject('companyId', {
        options: companiesOptions,
      });
    }
  }, [companiesOptions]);

  const globalError = getGlobalError(error, intl);

  return (
    <FormWrapper error={error} onSubmit={onSubmit}>
      <FormCard withShadow={false}>
        {globalError && (
          <Alert variant="danger" capitalize={false}>
            {globalError}
          </Alert>
        )}
        {inputs.map((input) => (
          <TextField
            key={input.name}
            onChange={onInputChange}
            value={input.value?.toString() ?? ''}
            label={input.label ?? ''}
            errors={input.validationErrors ?? []}
            onBlur={onLoseInputFocus}
            name={input.name}
            type={input.type}
            options={input.options}
            onSelectChange={onSelectChange}
          />
        ))}
        <Button
          isLoadingButton
          onClick={onSubmit}
          buttonVariant="contained"
          color="primary"
          type="submit"
          isLoading={isLoading || isSelectedLoading}
        >
          {translate(intl, 'COMPANY_SELECT_FORM.SELECT_COMPANY')}
        </Button>
      </FormCard>
    </FormWrapper>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.company.companyOptionsError,
  isLoading: state.company.companyOptionsLoading,
  companyOptions: state.company.companyOptions,
  selectedCompany: state.company.selectedCompany,
  selectedLocale: state.auth.selectedLocale,
  createdUserSetting: state.userSetting.createdUserSetting,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCompanyFetch: (intl: IntlShape) =>
    dispatch(companyService.fetchCompanyOptions(intl)),
  onCompanySelect: (selectCompanyId: number) =>
    dispatch(setSelectedCompany(selectCompanyId)),
  onSetSetting: (type: UserSettingType, value: string) =>
    dispatch(
      userSettingService.setUserSetting({
        type,
        value,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelectForm);
