import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Language } from '../../domain/Language';
import { ListResults } from '../../common/List/List';

export const fetchLanguageStart = () => ({
  type: actionTypes.FETCH_LANGUAGE_START,
});

export const fetchLanguageSuccess = (language: Language) => ({
  type: actionTypes.FETCH_LANGUAGE_SUCCESS,
  language,
});

export const fetchLanguageFail = (languageError: HttpError) => ({
  type: actionTypes.FETCH_LANGUAGE_FAIL,
  languageError,
});

export const fetchLanguagesStart = () => ({
  type: actionTypes.FETCH_LANGUAGES_START,
});

export const fetchLanguagesSuccess = (languages: Language[]) => ({
  type: actionTypes.FETCH_LANGUAGES_SUCCESS,
  languages,
});

export const fetchLanguagesFail = (languagesError: HttpError) => ({
  type: actionTypes.FETCH_LANGUAGES_FAIL,
  languagesError,
});

export const changeLanguageStatusStart = () => ({
  type: actionTypes.CHANGE_LANGUAGE_STATUS_START,
});

export const changeLanguageStatusSuccess = () => ({
  type: actionTypes.CHANGE_LANGUAGE_STATUS_SUCCESS,
});

export const changeLanguageStatusFail = (
  changeLanguageStatusError: HttpError,
) => ({
  type: actionTypes.CHANGE_LANGUAGE_STATUS_FAIL,
  changeLanguageStatusError,
});

export const fetchLanguagesListStart = () => ({
  type: actionTypes.FETCH_LANGUAGES_LIST_START,
});

export const fetchLanguagesListSuccess = (
  languagesList: ListResults<Language>,
) => ({
  type: actionTypes.FETCH_LANGUAGES_LIST_SUCCESS,
  languagesList,
});

export const fetchLanguagesListFail = (languageListError: HttpError) => ({
  type: actionTypes.FETCH_LANGUAGES_LIST_FAIL,
  languageListError,
});

export const resetLanguageStatusStore = () => ({
  type: actionTypes.RESET_LANGUAGE_STATUS_STORE,
});
