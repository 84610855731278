import * as actionTypes from './actionTypes';
import { UserSettingActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { UserSetting } from '../../domain/UserSetting';

export type UserSettingStateType = {
  setUserSettingLoading: boolean;
  setUserSettingError: HttpError;
  createdUserSetting: UserSetting | null;
};

export type UserSettingActionType = UserSettingStateType & {
  type: UserSettingActionTypes;
};

export const initialState: UserSettingStateType = {
  setUserSettingLoading: false,
  createdUserSetting: null,
  setUserSettingError: null,
};

const setUserSettingStart = (
  state: UserSettingStateType,
): UserSettingStateType => ({
  ...state,
  setUserSettingLoading: true,
});

const setUserSettingSuccess = (
  state: UserSettingStateType,
  action: UserSettingActionType,
): UserSettingStateType => ({
  ...state,
  setUserSettingLoading: false,
  setUserSettingError: null,
  createdUserSetting: action.createdUserSetting,
});

const setUserSettingFail = (
  state: UserSettingStateType,
  action: UserSettingActionType,
): UserSettingStateType => ({
  ...state,
  setUserSettingLoading: false,
  setUserSettingError: action.setUserSettingError,
});

const resetStore = () => ({
  ...initialState,
});

const completelyResetUserSettingStore = () => ({
  ...initialState,
});

const reducer = (state = initialState, action: UserSettingActionType) => {
  switch (action.type) {
    case actionTypes.SET_USER_SETTING_START:
      return setUserSettingStart(state);
    case actionTypes.SET_USER_SETTING_SUCCESS:
      return setUserSettingSuccess(state, action);
    case actionTypes.SET_USER_SETTING_FAIL:
      return setUserSettingFail(state, action);
    case actionTypes.RESET_USER_SETTING_STORE:
      return resetStore();
    case actionTypes.LOGOUT:
      return completelyResetUserSettingStore();
    default:
      return state;
  }
};

export default reducer;
