import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createCompanyStart,
  createCompanySuccess,
  createCompanyFail,
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  fetchCompaniesFail,
  fetchCompanyStart,
  fetchCompanySuccess,
  updateCompanyStart,
  fetchCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompanyStart,
  deleteCompanySuccess,
  deleteCompanyFail,
  fetchCompanyOptionsStart,
  fetchCompanyOptionsSuccess,
  fetchCompanyOptionsFail,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { getFormData } from '../../utility/form/formData';

const API_URL = '/companies';

export type CompanyCreateRequest = {
  name: string;
  ownerId: number;
  logo: File;
};

export type CompanyUpdateRequest = CompanyCreateRequest & {
  id: number;
};

export const fetchCompanies =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchCompaniesStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchCompaniesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCompaniesFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchCompany =
  (companyId: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchCompanyStart());
    return axios
      .get(`${API_URL}/${companyId}`)
      .then((response) => {
        dispatch(fetchCompanySuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCompanyFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const createCompany =
  (inputs: CompanyCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createCompanyStart());
    return axios
      .post(API_URL, getFormData(inputs))
      .then((response) => {
        dispatch(createCompanySuccess(response.data));
        showToast(translate(intl, 'SUCCESS.COMPANY_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createCompanyFail(err?.response?.data?.message));
      });
  };

export const updateCompany =
  (inputs: CompanyUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateCompanyStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, getFormData(inputs))
      .then(() => {
        dispatch(updateCompanySuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateCompanyFail(err?.response?.data?.message));
      });
  };

export const deleteCompany =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteCompanyStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteCompanySuccess());
        showToast(translate(intl, 'SUCCESS.COMPANY_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteCompanyFail(err?.response?.data?.message));
        showToast(err?.response?.data?.message, 'error');
      });
  };

export const fetchCompanyOptions =
  (intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchCompanyOptionsStart());
    return axios
      .get(`${API_URL}/options`)
      .then((response) => {
        dispatch(fetchCompanyOptionsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchCompanyOptionsFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };
