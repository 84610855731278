import * as actionTypes from './actionTypes';
import { ReviewActionTypes } from './actionTypes';
import { Review } from '../../domain/Review';
import { ListResults } from '../../common/List/List';
import { HttpError } from '../../config/Axios/axios-instance';

export type ReviewStateType = {
  reviewsList: ListResults<Review> | null;
  reviewsLoading: boolean;
  reviewsError: HttpError;
  reviewsListUpdateNeeded: boolean;
  reviewCreateLoading: boolean;
  reviewCreateError: HttpError;
  reviewCreateSuccess: boolean;
  createdReview: Review | null;
  reviewUpdateLoading: boolean;
  reviewUpdateError: HttpError;
  reviewUpdateSuccess: boolean;
  reviewDeleteLoading: boolean;
  reviewDeleteError: HttpError;
  reviewDeleteSuccess: boolean;
  globalReviewsList: ListResults<Review> | null;
  globalReviewsLoading: boolean;
  globalReviewsError: HttpError;
};

export type ReviewActionType = ReviewStateType & {
  type: ReviewActionTypes;
};

export const initialState: ReviewStateType = {
  reviewsList: null,
  reviewsLoading: true,
  reviewsError: null,
  reviewsListUpdateNeeded: false,
  reviewCreateLoading: false,
  reviewCreateError: null,
  reviewCreateSuccess: false,
  createdReview: null,
  reviewUpdateLoading: false,
  reviewUpdateError: null,
  reviewUpdateSuccess: false,
  reviewDeleteLoading: false,
  reviewDeleteError: null,
  reviewDeleteSuccess: false,
  globalReviewsList: null,
  globalReviewsLoading: true,
  globalReviewsError: null,
};

const fetchReviewsStart = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  reviewsLoading: true,
});

const fetchReviewsSuccess = (
  state: ReviewStateType,
  action: ReviewActionType,
): ReviewStateType => ({
  ...state,
  reviewsList: action.reviewsList,
  reviewsLoading: false,
  reviewsError: null,
  reviewsListUpdateNeeded: false,
  reviewCreateSuccess: false,
  reviewUpdateSuccess: false,
  reviewDeleteSuccess: false,
});

const fetchReviewsFail = (
  state: ReviewStateType,
  action: ReviewActionType,
): ReviewStateType => ({
  ...state,
  reviewsError: action.reviewsError,
  reviewsLoading: false,
});

const createReviewStart = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  reviewCreateLoading: true,
});

const createReviewSuccess = (
  state: ReviewStateType,
  action: ReviewActionType,
): ReviewStateType => ({
  ...state,
  reviewCreateLoading: false,
  reviewCreateError: null,
  reviewCreateSuccess: true,
  createdReview: action.createdReview,
  reviewsListUpdateNeeded: true,
  reviewsList: state.reviewsList,
});

const createReviewFail = (
  state: ReviewStateType,
  action: ReviewActionType,
): ReviewStateType => ({
  ...state,
  reviewCreateLoading: false,
  reviewCreateError: action.reviewCreateError,
});

const updateReviewStart = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  reviewUpdateLoading: true,
});

const updateReviewSuccess = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  reviewUpdateLoading: false,
  reviewUpdateError: null,
  reviewUpdateSuccess: true,
  reviewsListUpdateNeeded: true,
});

const updateReviewFail = (
  state: ReviewStateType,
  action: ReviewActionType,
): ReviewStateType => ({
  ...state,
  reviewUpdateLoading: false,
  reviewUpdateError: action.reviewUpdateError,
});

const deleteReviewStart = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  reviewDeleteLoading: true,
});

const deleteReviewSuccess = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  reviewDeleteLoading: false,
  reviewDeleteError: null,
  reviewDeleteSuccess: true,
  reviewsListUpdateNeeded: true,
});

const deleteReviewFail = (
  state: ReviewStateType,
  action: ReviewActionType,
): ReviewStateType => ({
  ...state,
  reviewDeleteLoading: false,
  reviewDeleteError: action.reviewDeleteError,
});

const fetchGlobalReviewsStart = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  reviewsLoading: true,
});

const fetchGlobalReviewsSuccess = (
  state: ReviewStateType,
  action: ReviewActionType,
): ReviewStateType => ({
  ...state,
  globalReviewsList: action.globalReviewsList,
  globalReviewsLoading: false,
  globalReviewsError: null,
  reviewsListUpdateNeeded: false,
  reviewCreateSuccess: false,
  reviewUpdateSuccess: false,
  reviewDeleteSuccess: false,
});

const fetchGlobalReviewsFail = (
  state: ReviewStateType,
  action: ReviewActionType,
): ReviewStateType => ({
  ...state,
  globalReviewsError: action.globalReviewsError,
  globalReviewsLoading: false,
});

const resetCreatedReview = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  createdReview: null,
  reviewCreateError: null,
  reviewCreateLoading: false,
  reviewCreateSuccess: false,
});

const resetUpdatedReview = (state: ReviewStateType): ReviewStateType => ({
  ...state,
  reviewUpdateError: null,
  reviewUpdateLoading: false,
  reviewUpdateSuccess: false,
  reviewsListUpdateNeeded: false,
});

const resetReviewStore = (): ReviewStateType => ({
  ...initialState,
});

const completelyResetReviewStore = (): ReviewStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ReviewActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_REVIEWS_START:
      return fetchReviewsStart(state);
    case actionTypes.FETCH_REVIEWS_SUCCESS:
      return fetchReviewsSuccess(state, action);
    case actionTypes.FETCH_REVIEWS_FAIL:
      return fetchReviewsFail(state, action);
    case actionTypes.CREATE_REVIEW_START:
      return createReviewStart(state);
    case actionTypes.CREATE_REVIEW_SUCCESS:
      return createReviewSuccess(state, action);
    case actionTypes.CREATE_REVIEW_FAIL:
      return createReviewFail(state, action);
    case actionTypes.UPDATE_REVIEW_START:
      return updateReviewStart(state);
    case actionTypes.UPDATE_REVIEW_SUCCESS:
      return updateReviewSuccess(state);
    case actionTypes.UPDATE_REVIEW_FAIL:
      return updateReviewFail(state, action);
    case actionTypes.DELETE_REVIEW_START:
      return deleteReviewStart(state);
    case actionTypes.DELETE_REVIEW_SUCCESS:
      return deleteReviewSuccess(state);
    case actionTypes.DELETE_REVIEW_FAIL:
      return deleteReviewFail(state, action);
    case actionTypes.FETCH_GLOBAL_REVIEWS_START:
      return fetchGlobalReviewsStart(state);
    case actionTypes.FETCH_GLOBAL_REVIEWS_SUCCESS:
      return fetchGlobalReviewsSuccess(state, action);
    case actionTypes.FETCH_GLOBAL_REVIEWS_FAIL:
      return fetchGlobalReviewsFail(state, action);
    case actionTypes.RESET_REVIEW_STORE:
      return resetReviewStore();
    case actionTypes.RESET_CREATED_REVIEW:
      return resetCreatedReview(state);
    case actionTypes.RESET_UPDATED_REVIEW:
      return resetUpdatedReview(state);
    case actionTypes.LOGOUT:
      return completelyResetReviewStore();
    default:
      return state;
  }
};

export default reducer;
