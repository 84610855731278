import React from 'react';
import styles from './Footer.module.scss';
import FooterContent from './FooterContent/FooterContent';
import FooterCopyright from './FooterCopyright/FooterCopyright';

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <FooterContent />
      <FooterCopyright />
    </div>
  );
};

export default Footer;
