import { Locale } from '../../domain/Translation';
import { domainToLocaleData } from '../../config/domainToLocaleData';
import { DEFAULT_LOCALE } from '../../config/constants';

export const getLocaleUrl = (route: string, locale: Locale) =>
  route.replace(':locale', locale);

export const getLocaleFromUrl = (url: string) => {
  const topLevelDomain = '.' + url.split('.').pop();
  return domainToLocaleData[topLevelDomain] || DEFAULT_LOCALE;
};
