import React, { ReactNode } from 'react';
import styles from './FormWrapper.module.scss';
import Form from '../Form/Form';
import { HttpError } from '../../config/Axios/axios-instance';

export type Props = {
  children?: ReactNode;
  error: HttpError;
  onSubmit: () => void;
};

const FormWrapper = ({ children, error, onSubmit }: Props) => (
  <div className={styles.formContainer}>
    <Form className={styles.form} error={error} onSubmit={onSubmit}>
      {children}
    </Form>
  </div>
);

export default FormWrapper;
