export const FETCH_TESTS_LIST_START = 'FETCH_TESTS_LIST_START';
export const FETCH_TESTS_LIST_SUCCESS = 'FETCH_TESTS_LIST_SUCCESS';
export const FETCH_TESTS_LIST_FAIL = 'FETCH_TESTS_LIST_FAIL';

export const FETCH_TEST_START = 'FETCH_TEST_START';
export const FETCH_TEST_SUCCESS = 'FETCH_TEST_SUCCESS';
export const FETCH_TEST_FAIL = 'FETCH_TEST_FAIL';

export const CREATE_TEST_START = 'CREATE_TEST_START';
export const CREATE_TEST_SUCCESS = 'CREATE_TEST_SUCCESS';
export const CREATE_TEST_FAIL = 'CREATE_TEST_FAIL';

export const UPDATE_TEST_START = 'UPDATE_TEST_START';
export const UPDATE_TEST_SUCCESS = 'UPDATE_TEST_SUCCESS';
export const UPDATE_TEST_FAIL = 'UPDATE_TEST_FAIL';

export const DELETE_TEST_START = 'DELETE_TEST_START';
export const DELETE_TEST_SUCCESS = 'DELETE_TEST_SUCCESS';
export const DELETE_TEST_FAIL = 'DELETE_TEST_FAIL';

export const RESET_CREATED_TEST_STORE = 'RESET_CREATED_TEST';
export const RESET_TEST_STORE = 'RESET_TEST_STORE';

export const LOGOUT = 'LOGOUT';

export type TestActionTypes =
  | typeof FETCH_TESTS_LIST_START
  | typeof FETCH_TESTS_LIST_SUCCESS
  | typeof FETCH_TESTS_LIST_FAIL
  | typeof FETCH_TEST_START
  | typeof FETCH_TEST_SUCCESS
  | typeof FETCH_TEST_FAIL
  | typeof CREATE_TEST_START
  | typeof CREATE_TEST_SUCCESS
  | typeof CREATE_TEST_FAIL
  | typeof UPDATE_TEST_START
  | typeof UPDATE_TEST_SUCCESS
  | typeof UPDATE_TEST_FAIL
  | typeof DELETE_TEST_START
  | typeof DELETE_TEST_SUCCESS
  | typeof DELETE_TEST_FAIL
  | typeof RESET_CREATED_TEST_STORE
  | typeof RESET_TEST_STORE
  | typeof LOGOUT;
