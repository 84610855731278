export const FETCH_CURRENT_QUESTION_START = 'FETCH_CURRENT_QUESTION_START';
export const FETCH_CURRENT_QUESTION_SUCCESS = 'FETCH_CURRENT_QUESTION_SUCCESS';
export const FETCH_CURRENT_QUESTION_FAIL = 'FETCH_CURRENT_QUESTION_FAIL';

export const CREATE_USER_ASSESSMENT_START = 'CREATE_USER_ASSESSMENT_START';
export const CREATE_USER_ASSESSMENT_SUCCESS = 'CREATE_USER_ASSESSMENT_SUCCESS';
export const CREATE_USER_ASSESSMENT_FAIL = 'CREATE_USER_ASSESSMENT_FAIL';

export const CREATE_RESPONDENT_START = 'CREATE_RESPONDENT_START';
export const CREATE_RESPONDENT_SUCCESS = 'CREATE_RESPONDENT_SUCCESS';
export const CREATE_RESPONDENT_FAIL = 'CREATE_RESPONDENT_FAIL';
export const CREATE_RESPONDENT_RESET = 'CREATE_RESPONDENT_RESET';

export const COMPLETE_USER_ASSESSMENT_START = 'COMPLETE_USER_ASSESSMENT_START';
export const COMPLETE_USER_ASSESSMENT_SUCCESS =
  'COMPLETE_USER_ASSESSMENT_SUCCESS';
export const COMPLETE_USER_ASSESSMENT_FAIL = 'COMPLETE_USER_ASSESSMENT_FAIL';

export const FETCH_RESULTS_START = 'FETCH_RESULTS_START';
export const FETCH_RESULTS_SUCCESS = 'FETCH_RESULTS_SUCCESS';
export const FETCH_RESULTS_FAIL = 'FETCH_RESULTS_FAIL';

export const FETCH_RESPONDENTS_START = 'FETCH_RESPONDENTS_START';
export const FETCH_RESPONDENTS_SUCCESS = 'FETCH_RESPONDENTS_SUCCESS';
export const FETCH_RESPONDENTS_FAIL = 'FETCH_RESPONDENTS_FAIL';
export const RESET_RESPONDENTS = 'RESET_RESPONDENTS';

export const SET_USER_ASSESSMENT_TOKEN_DATA = 'SET_USER_ASSESSMENT_TOKEN_DATA';

export const RESET_USER_ASSESSMENT_STORE = 'RESET_USER_ASSESSMENT_STORE';
export const LOGOUT = 'LOGOUT';

export type UserAssessmentActionTypes =
  | typeof FETCH_CURRENT_QUESTION_START
  | typeof FETCH_CURRENT_QUESTION_SUCCESS
  | typeof FETCH_CURRENT_QUESTION_FAIL
  | typeof CREATE_USER_ASSESSMENT_START
  | typeof CREATE_USER_ASSESSMENT_SUCCESS
  | typeof CREATE_USER_ASSESSMENT_FAIL
  | typeof CREATE_RESPONDENT_START
  | typeof CREATE_RESPONDENT_SUCCESS
  | typeof CREATE_RESPONDENT_FAIL
  | typeof CREATE_RESPONDENT_RESET
  | typeof COMPLETE_USER_ASSESSMENT_START
  | typeof COMPLETE_USER_ASSESSMENT_SUCCESS
  | typeof COMPLETE_USER_ASSESSMENT_FAIL
  | typeof FETCH_RESULTS_START
  | typeof FETCH_RESULTS_SUCCESS
  | typeof FETCH_RESULTS_FAIL
  | typeof FETCH_RESPONDENTS_START
  | typeof FETCH_RESPONDENTS_SUCCESS
  | typeof FETCH_RESPONDENTS_FAIL
  | typeof RESET_RESPONDENTS
  | typeof SET_USER_ASSESSMENT_TOKEN_DATA
  | typeof RESET_USER_ASSESSMENT_STORE
  | typeof LOGOUT;
