import { useEffect, useState } from 'react';

export interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

export const BREAK_DESKTOP = 1920;
export const BREAK_LAPTOP = 1300;
export const BREAK_TABLET = 900;

const useWindowSize = (): WindowSize => {
  const isClient = typeof window === 'object';

  const getSize = (): WindowSize => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  });

  const [windowSize, setWindowSize] = useState<WindowSize>(getSize);

  useEffect(() => {
    if (!isClient) {
      return undefined;
    }

    const handleResize = (): void => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  });

  return windowSize;
};

export default useWindowSize;
