import * as actionTypes from './actionTypes';
import { TraitActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Trait } from '../../domain/Trait';

export type TraitStateType = {
  traitsList: ListResults<Trait> | null;
  traitsLoading: boolean;
  traitsError: HttpError;
  traitsListUpdateNeeded: boolean;
  traitCreateLoading: boolean;
  traitCreateError: HttpError;
  traitCreateSuccess: boolean;
  createdTrait: Trait | null;
  traitUpdateLoading: boolean;
  traitUpdateError: HttpError;
  traitUpdateSuccess: boolean;
  trait: Trait | null;
  traitLoading: boolean;
  traitError: HttpError;
  traitDeleteLoading: boolean;
  traitDeleteError: HttpError;
  traitDeleteSuccess: boolean;
  allTraitsLoading: boolean;
  allTraitsError: HttpError;
  allTraits: Trait[] | null;
};

export type TraitActionType = TraitStateType & {
  type: TraitActionTypes;
};

export const initialState: TraitStateType = {
  traitsList: null,
  traitsLoading: true,
  traitsError: null,
  traitsListUpdateNeeded: false,
  traitCreateLoading: false,
  traitCreateError: null,
  traitCreateSuccess: false,
  createdTrait: null,
  traitUpdateLoading: false,
  traitUpdateError: null,
  traitUpdateSuccess: false,
  trait: null,
  traitLoading: false,
  traitError: null,
  traitDeleteLoading: false,
  traitDeleteError: null,
  traitDeleteSuccess: false,
  allTraitsLoading: false,
  allTraitsError: null,
  allTraits: null,
};

const fetchTraitsStart = (state: TraitStateType): TraitStateType => ({
  ...state,
  traitsLoading: true,
});

const fetchTraitsSuccess = (
  state: TraitStateType,
  action: TraitActionType,
): TraitStateType => ({
  ...state,
  traitsList: action.traitsList,
  traitsLoading: false,
  traitsError: null,
  traitsListUpdateNeeded: false,
  traitCreateSuccess: false,
  traitUpdateSuccess: false,
  traitDeleteSuccess: false,
});

const fetchTraitsFail = (
  state: TraitStateType,
  action: TraitActionType,
): TraitStateType => ({
  ...state,
  traitsError: action.traitsError,
  traitsLoading: false,
});

const fetchTraitStart = (state: TraitStateType): TraitStateType => ({
  ...state,
  traitLoading: true,
  traitUpdateSuccess: false,
  traitCreateSuccess: false,
  traitCreateError: null,
  traitUpdateError: null,
});

const fetchTraitSuccess = (
  state: TraitStateType,
  action: TraitActionType,
): TraitStateType => ({
  ...state,
  trait: action.trait,
  traitLoading: false,
  traitError: null,
});

const fetchTraitFail = (
  state: TraitStateType,
  action: TraitActionType,
): TraitStateType => ({
  ...state,
  traitError: action.traitError,
  traitLoading: false,
});

const createTraitStart = (state: TraitStateType): TraitStateType => ({
  ...state,
  traitCreateLoading: true,
});

const createTraitSuccess = (
  state: TraitStateType,
  action: TraitActionType,
): TraitStateType => ({
  ...state,
  traitCreateLoading: false,
  traitCreateError: null,
  traitCreateSuccess: true,
  createdTrait: action.createdTrait,
  traitsListUpdateNeeded: true,
  traitsList: state.traitsList,
});

const createTraitFail = (
  state: TraitStateType,
  action: TraitActionType,
): TraitStateType => ({
  ...state,
  traitCreateLoading: false,
  traitCreateError: action.traitCreateError,
});

const updateTraitStart = (state: TraitStateType): TraitStateType => ({
  ...state,
  traitUpdateLoading: true,
});

const updateTraitSuccess = (state: TraitStateType): TraitStateType => ({
  ...state,
  traitUpdateLoading: false,
  traitUpdateError: null,
  traitUpdateSuccess: true,
  traitsListUpdateNeeded: true,
});

const updateTraitFail = (
  state: TraitStateType,
  action: TraitActionType,
): TraitStateType => ({
  ...state,
  traitUpdateLoading: false,
  traitUpdateError: action.traitUpdateError,
});

const deleteTraitStart = (state: TraitStateType): TraitStateType => ({
  ...state,
  traitDeleteLoading: true,
});

const deleteTraitSuccess = (state: TraitStateType): TraitStateType => ({
  ...state,
  traitDeleteLoading: false,
  traitDeleteError: null,
  traitDeleteSuccess: true,
  traitsListUpdateNeeded: true,
});

const deleteTraitFail = (
  state: TraitStateType,
  action: TraitActionType,
): TraitStateType => ({
  ...state,
  traitDeleteLoading: false,
  traitDeleteError: action.traitDeleteError,
});

const fetchAllTraitsStart = (state: TraitStateType): TraitStateType => ({
  ...state,
  allTraitsLoading: true,
});

const fetchAllTraitsSuccess = (
  state: TraitStateType,
  action: TraitActionType,
): TraitStateType => ({
  ...state,
  allTraits: action.allTraits,
  allTraitsLoading: false,
});

const fetchAllTraitsFail = (
  state: TraitStateType,
  action: TraitActionType,
): TraitStateType => ({
  ...state,
  allTraitsError: action.allTraitsError,
  allTraitsLoading: false,
});

const resetCreatedTrait = (state: TraitStateType): TraitStateType => ({
  ...state,
  createdTrait: null,
  traitCreateError: null,
  traitCreateLoading: false,
  traitCreateSuccess: false,
});

const resetUpdatedTrait = (state: TraitStateType): TraitStateType => ({
  ...state,
  trait: null,
  traitUpdateError: null,
  traitUpdateLoading: false,
  traitUpdateSuccess: false,
  traitsListUpdateNeeded: false,
});

const resetTraitStore = (): TraitStateType => ({
  ...initialState,
});

const completelyResetTraitStore = (): TraitStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TraitActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TRAITS_START:
      return fetchTraitsStart(state);
    case actionTypes.FETCH_TRAITS_SUCCESS:
      return fetchTraitsSuccess(state, action);
    case actionTypes.FETCH_TRAITS_FAIL:
      return fetchTraitsFail(state, action);
    case actionTypes.FETCH_TRAIT_START:
      return fetchTraitStart(state);
    case actionTypes.FETCH_TRAIT_SUCCESS:
      return fetchTraitSuccess(state, action);
    case actionTypes.FETCH_TRAIT_FAIL:
      return fetchTraitFail(state, action);
    case actionTypes.CREATE_TRAIT_START:
      return createTraitStart(state);
    case actionTypes.CREATE_TRAIT_SUCCESS:
      return createTraitSuccess(state, action);
    case actionTypes.CREATE_TRAIT_FAIL:
      return createTraitFail(state, action);
    case actionTypes.UPDATE_TRAIT_START:
      return updateTraitStart(state);
    case actionTypes.UPDATE_TRAIT_SUCCESS:
      return updateTraitSuccess(state);
    case actionTypes.UPDATE_TRAIT_FAIL:
      return updateTraitFail(state, action);
    case actionTypes.DELETE_TRAIT_START:
      return deleteTraitStart(state);
    case actionTypes.DELETE_TRAIT_SUCCESS:
      return deleteTraitSuccess(state);
    case actionTypes.DELETE_TRAIT_FAIL:
      return deleteTraitFail(state, action);
    case actionTypes.FETCH_ALL_TRAITS_START:
      return fetchAllTraitsStart(state);
    case actionTypes.FETCH_ALL_TRAITS_SUCCESS:
      return fetchAllTraitsSuccess(state, action);
    case actionTypes.FETCH_ALL_TRAITS_FAIL:
      return fetchAllTraitsFail(state, action);
    case actionTypes.RESET_TRAIT_STORE:
      return resetTraitStore();
    case actionTypes.RESET_CREATED_TRAIT:
      return resetCreatedTrait(state);
    case actionTypes.RESET_UPDATED_TRAIT:
      return resetUpdatedTrait(state);
    case actionTypes.LOGOUT:
      return completelyResetTraitStore();
    default:
      return state;
  }
};

export default reducer;
