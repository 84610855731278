import React from 'react';
import styles from './FooterContent.module.scss';
import LogoLink from '../../PublicNavigation/LogoLink/LogoLink';
import logo from '../../../assets/cvgorilla_logo.png';
import { translate } from '../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import FooterNavLinkBox from '../FooterNavLinkBox/FooterNavLinkBox';
import SocialMediaIcons from '../SocialMediaIcons/SocialMediaIcons';

const FooterContent = () => {
  const intl = useIntl();

  return (
    <div className={styles.footerContentContainer}>
      <div className={styles.logoWithDescriptionContainer}>
        <LogoLink logoSrc={logo} />

        <p className={styles.logoWithDescriptionDescription}>
          {translate(intl, 'FOOTER.DESCRIPTION')}
        </p>

        <SocialMediaIcons />
      </div>

      <div className={styles.navLinksContainer}>
        {Array.from({ length: 3 }, (_, i) => i + 1).map((i) => (
          <FooterNavLinkBox key={i} index={i} />
        ))}
      </div>
    </div>
  );
};

export default FooterContent;
