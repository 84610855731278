import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Payment } from '../../domain/Payment';

export const fetchPaymentsStart = () => ({
  type: actionTypes.FETCH_PAYMENTS_START,
});

export const fetchPaymentsSuccess = (paymentsList: ListResults<Payment>) => ({
  type: actionTypes.FETCH_PAYMENTS_SUCCESS,
  paymentsList,
});

export const fetchPaymentsFail = (paymentsError: HttpError) => ({
  type: actionTypes.FETCH_PAYMENTS_FAIL,
  paymentsError,
});

export const createPaymentStart = () => ({
  type: actionTypes.CREATE_PAYMENT_START,
});

export const createPaymentSuccess = (redirectUrl: string) => ({
  type: actionTypes.CREATE_PAYMENT_SUCCESS,
  redirectUrl,
});

export const createPaymentFail = (paymentCreateError: HttpError) => ({
  type: actionTypes.CREATE_PAYMENT_FAIL,
  paymentCreateError,
});

export const resetPaymentStore = () => ({
  type: actionTypes.RESET_PAYMENT_STORE,
});
