import React from 'react';
import styles from './SocialMediaIcons.module.scss';
import facebook from '../../../assets/footer/facebook.png';
import instagram from '../../../assets/footer/instagram.png';
import twitter from '../../../assets/footer/twitter.png';
import youtube from '../../../assets/footer/youtube.png';
import { routes } from '../../../config/Router/routes';
import { Link } from 'react-router-dom';

const SocialMediaIcons = () => {
  const socialMediaLinks = [
    { icon: facebook, altLabel: 'facebook', to: routes.login },
    { icon: instagram, altLabel: 'instagram', to: routes.login },
    { icon: twitter, altLabel: 'twitter', to: routes.login },
    { icon: youtube, altLabel: 'youtube', to: routes.login },
  ];

  return (
    <div className={styles.socialMediaIconContainer}>
      {socialMediaLinks.map((socialMediaInfo, index) => (
        <Link key={index} to={socialMediaInfo.to}>
          <img src={socialMediaInfo.icon} alt={socialMediaInfo.altLabel} />
        </Link>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
