import * as actionTypes from './actionTypes';
import { JobTitleActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { JobTitle } from '../../domain/JobTitle';

export type JobTitleStateType = {
  jobTitlesList: ListResults<JobTitle> | null;
  jobTitlesLoading: boolean;
  jobTitlesError: HttpError;
  jobTitlesListUpdateNeeded: boolean;
  jobTitleCreateLoading: boolean;
  jobTitleCreateError: HttpError;
  jobTitleCreateSuccess: boolean;
  createdJobTitle: JobTitle | null;
  jobTitleUpdateLoading: boolean;
  jobTitleUpdateError: HttpError;
  jobTitleUpdateSuccess: boolean;
  jobTitle: JobTitle | null;
  jobTitleLoading: boolean;
  jobTitleError: HttpError;
  jobTitleDeleteLoading: boolean;
  jobTitleDeleteError: HttpError;
  jobTitleDeleteSuccess: boolean;
  allCompanyJobTitles: JobTitle[] | [];
  allCompanyJobTitlesLoading: boolean;
  allCompanyJobTitlesError: HttpError;
};

export type JobTitleActionType = JobTitleStateType & {
  type: JobTitleActionTypes;
};

export const initialState: JobTitleStateType = {
  jobTitlesList: null,
  jobTitlesLoading: true,
  jobTitlesError: null,
  jobTitlesListUpdateNeeded: false,
  jobTitleCreateLoading: false,
  jobTitleCreateError: null,
  jobTitleCreateSuccess: false,
  createdJobTitle: null,
  jobTitleUpdateLoading: false,
  jobTitleUpdateError: null,
  jobTitleUpdateSuccess: false,
  jobTitle: null,
  jobTitleLoading: false,
  jobTitleError: null,
  jobTitleDeleteLoading: false,
  jobTitleDeleteError: null,
  jobTitleDeleteSuccess: false,
  allCompanyJobTitles: [],
  allCompanyJobTitlesLoading: false,
  allCompanyJobTitlesError: null,
};

const fetchJobTitlesStart = (state: JobTitleStateType): JobTitleStateType => ({
  ...state,
  jobTitlesLoading: true,
});

const fetchJobTitlesSuccess = (
  state: JobTitleStateType,
  action: JobTitleActionType,
): JobTitleStateType => ({
  ...state,
  jobTitlesList: action.jobTitlesList,
  jobTitlesLoading: false,
  jobTitlesError: null,
  jobTitlesListUpdateNeeded: false,
  jobTitleCreateSuccess: false,
  jobTitleUpdateSuccess: false,
  jobTitleDeleteSuccess: false,
});

const fetchJobTitlesFail = (
  state: JobTitleStateType,
  action: JobTitleActionType,
): JobTitleStateType => ({
  ...state,
  jobTitlesError: action.jobTitlesError,
  jobTitlesLoading: false,
});

const fetchJobTitleStart = (state: JobTitleStateType): JobTitleStateType => ({
  ...state,
  jobTitleLoading: true,
  jobTitleUpdateSuccess: false,
  jobTitleCreateSuccess: false,
  jobTitleCreateError: null,
  jobTitleUpdateError: null,
});

const fetchJobTitleSuccess = (
  state: JobTitleStateType,
  action: JobTitleActionType,
): JobTitleStateType => ({
  ...state,
  jobTitle: action.jobTitle,
  jobTitleLoading: false,
  jobTitleError: null,
});

const fetchJobTitleFail = (
  state: JobTitleStateType,
  action: JobTitleActionType,
): JobTitleStateType => ({
  ...state,
  jobTitleError: action.jobTitleError,
  jobTitleLoading: false,
});

const createJobTitleStart = (state: JobTitleStateType): JobTitleStateType => ({
  ...state,
  jobTitleCreateLoading: true,
});

const createJobTitleSuccess = (
  state: JobTitleStateType,
  action: JobTitleActionType,
): JobTitleStateType => ({
  ...state,
  jobTitleCreateLoading: false,
  jobTitleCreateError: null,
  jobTitleCreateSuccess: true,
  createdJobTitle: action.createdJobTitle,
  jobTitlesListUpdateNeeded: true,
  jobTitlesList: state.jobTitlesList
    ? {
        total: state.jobTitlesList.total + 1,
        result: [
          ...state.jobTitlesList.result,
          action.createdJobTitle,
        ] as JobTitle[],
      }
    : null,
});

const createJobTitleFail = (
  state: JobTitleStateType,
  action: JobTitleActionType,
): JobTitleStateType => ({
  ...state,
  jobTitleCreateLoading: false,
  jobTitleCreateError: action.jobTitleCreateError,
});

const updateJobTitleStart = (state: JobTitleStateType): JobTitleStateType => ({
  ...state,
  jobTitleUpdateLoading: true,
});

const updateJobTitleSuccess = (
  state: JobTitleStateType,
): JobTitleStateType => ({
  ...state,
  jobTitleUpdateLoading: false,
  jobTitleUpdateError: null,
  jobTitleUpdateSuccess: true,
  jobTitlesListUpdateNeeded: true,
});

const updateJobTitleFail = (
  state: JobTitleStateType,
  action: JobTitleActionType,
): JobTitleStateType => ({
  ...state,
  jobTitleUpdateLoading: false,
  jobTitleUpdateError: action.jobTitleUpdateError,
});

const deleteJobTitleStart = (state: JobTitleStateType): JobTitleStateType => ({
  ...state,
  jobTitleDeleteLoading: true,
});

const deleteJobTitleSuccess = (
  state: JobTitleStateType,
): JobTitleStateType => ({
  ...state,
  jobTitleDeleteLoading: false,
  jobTitleDeleteError: null,
  jobTitleDeleteSuccess: true,
  jobTitlesListUpdateNeeded: true,
});

const deleteJobTitleFail = (
  state: JobTitleStateType,
  action: JobTitleActionType,
): JobTitleStateType => ({
  ...state,
  jobTitleDeleteLoading: false,
  jobTitleDeleteError: action.jobTitleDeleteError,
});

const fetchAllCompanyJobTilesStart = (
  state: JobTitleStateType,
): JobTitleStateType => ({
  ...state,
  allCompanyJobTitlesLoading: true,
});

const fetchAllCompanyJobTilesSuccess = (
  state: JobTitleStateType,
  action: JobTitleActionType,
): JobTitleStateType => ({
  ...state,
  allCompanyJobTitles: action.allCompanyJobTitles,
  allCompanyJobTitlesLoading: false,
});

const fetchAllCompanyJobTilesFail = (
  state: JobTitleStateType,
  action: JobTitleActionType,
): JobTitleStateType => ({
  ...state,
  allCompanyJobTitlesError: action.allCompanyJobTitlesError,
  allCompanyJobTitlesLoading: false,
});

const resetCreatedJobTitle = (state: JobTitleStateType): JobTitleStateType => ({
  ...state,
  createdJobTitle: null,
});

const resetJobTitleStore = (): JobTitleStateType => ({
  ...initialState,
});

const completelyResetJobTitleStore = (): JobTitleStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: JobTitleActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_JOB_TITLES_START:
      return fetchJobTitlesStart(state);
    case actionTypes.FETCH_JOB_TITLES_SUCCESS:
      return fetchJobTitlesSuccess(state, action);
    case actionTypes.FETCH_JOB_TITLES_FAIL:
      return fetchJobTitlesFail(state, action);
    case actionTypes.FETCH_JOB_TITLE_START:
      return fetchJobTitleStart(state);
    case actionTypes.FETCH_JOB_TITLE_SUCCESS:
      return fetchJobTitleSuccess(state, action);
    case actionTypes.FETCH_JOB_TITLE_FAIL:
      return fetchJobTitleFail(state, action);
    case actionTypes.CREATE_JOB_TITLE_START:
      return createJobTitleStart(state);
    case actionTypes.CREATE_JOB_TITLE_SUCCESS:
      return createJobTitleSuccess(state, action);
    case actionTypes.CREATE_JOB_TITLE_FAIL:
      return createJobTitleFail(state, action);
    case actionTypes.UPDATE_JOB_TITLE_START:
      return updateJobTitleStart(state);
    case actionTypes.UPDATE_JOB_TITLE_SUCCESS:
      return updateJobTitleSuccess(state);
    case actionTypes.UPDATE_JOB_TITLE_FAIL:
      return updateJobTitleFail(state, action);
    case actionTypes.DELETE_JOB_TITLE_START:
      return deleteJobTitleStart(state);
    case actionTypes.DELETE_JOB_TITLE_SUCCESS:
      return deleteJobTitleSuccess(state);
    case actionTypes.DELETE_JOB_TITLE_FAIL:
      return deleteJobTitleFail(state, action);
    case actionTypes.FETCH_ALL_COMPANY_JOB_TITLES_START:
      return fetchAllCompanyJobTilesStart(state);
    case actionTypes.FETCH_ALL_COMPANY_JOB_TITLES_SUCCESS:
      return fetchAllCompanyJobTilesSuccess(state, action);
    case actionTypes.FETCH_ALL_COMPANY_JOB_TITLES_FAIL:
      return fetchAllCompanyJobTilesFail(state, action);
    case actionTypes.RESET_JOB_TITLE_STORE:
      return resetJobTitleStore();
    case actionTypes.RESET_CREATED_JOB_TITLE:
      return resetCreatedJobTitle(state);
    case actionTypes.LOGOUT:
      return completelyResetJobTitleStore();
    default:
      return state;
  }
};

export default reducer;
