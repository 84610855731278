import React, { useEffect } from 'react';
import styles from './PlansSection.module.scss';
import { IntlShape, useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import PlanCard from './PlanCard/PlanCard';
import * as paymentService from '../../../../store/payment/service';
import { PaymentCreateRequest } from '../../../../store/payment/service';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { PaymentType, SubscriptionType } from '../../../../domain/Payment';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';
import cx from 'classnames';
import { User } from '../../../../domain/User';

type Props = {
  onCreatePayment: (
    inputs: PaymentCreateRequest,
    currentUser: User | null,
    intl: IntlShape,
  ) => void;
  redirectUrl: string | null;
  isAuthenticated: boolean;
  paymentCreateLoading: boolean;
  isSmaller?: boolean;
  currentUser: User | null;
};

export const cards = [
  {
    title: 'PLAN_1.TITLE',
    pros: ['PLAN_1.PROS_1', 'PLAN_1.PROS_2'],
    subtitle: 'PLAN_1.SUBTITLE',
    isSubtitleBelow: true,
    description: 'PLAN_1.DESCRIPTION',
    price: 'PLAN_1.PRICE',
    subscriptionType: SubscriptionType.TRIAL,
  },
  {
    title: 'PLAN_2.TITLE',
    pros: [
      'PLAN_2.PROS_1',
      'PLAN_2.PROS_2',
      'PLAN_2.PROS_3',
      'PLAN_2.PROS_4',
      'PLAN_2.PROS_5',
    ],
    subtitle: 'PLAN_2.SUBTITLE',
    description: 'PLAN_2.DESCRIPTION',
    price: 'PLAN_2.PRICE',
    ad: 'PLAN_2.AD',
    subscriptionType: SubscriptionType.DETAILED,
  },
  {
    title: 'PLAN_3.TITLE',
    pros: [
      'PLAN_3.PROS_1',
      'PLAN_3.PROS_2',
      'PLAN_3.PROS_3',
      'PLAN_3.PROS_4',
      'PLAN_3.PROS_5',
    ],
    subtitle: 'PLAN_3.SUBTITLE',
    description: 'PLAN_3.DESCRIPTION',
    price: 'PLAN_3.PRICE',
    ad: 'PLAN_3.AD',
    subscriptionType: SubscriptionType.MEDIUM,
  },
  {
    title: 'PLAN_4.TITLE',
    pros: [
      'PLAN_4.PROS_1',
      'PLAN_4.PROS_2',
      'PLAN_4.PROS_3',
      'PLAN_4.PROS_4',
      'PLAN_4.PROS_5',
      'PLAN_4.PROS_6',
    ],
    subtitle: 'PLAN_4.SUBTITLE',
    description: 'PLAN_4.DESCRIPTION',
    price: 'PLAN_4.PRICE',
    oldPrice: 'PLAN_4.OLD_PRICE',
    promotion: true,
    subscriptionType: SubscriptionType.UNLIMITED,
  },
];

const PlansSection = ({
  onCreatePayment,
  redirectUrl,
  isAuthenticated,
  paymentCreateLoading,
  isSmaller,
  currentUser,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  const handlePurchaseClick = (subscriptionType: SubscriptionType) => {
    if (!isAuthenticated) {
      navigate(routes.login);
      return;
    }

    onCreatePayment(
      {
        subscriptionType: subscriptionType,
        paymentType: PaymentType.TESTS_TOP_UP,
      },
      currentUser,
      intl,
    );
  };

  return (
    <section
      className={cx(styles.sectionContainer, { [styles.smaller]: isSmaller })}
    >
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: translate(intl, 'PLANS_SECTION.TITLE'),
        }}
      />
      <div className={styles.cards}>
        {cards.map((card, index) => (
          <PlanCard
            title={card.title}
            pros={card.pros}
            key={index}
            subtitle={card.subtitle}
            isSubtitleBelow={card.isSubtitleBelow}
            description={card.description}
            price={card.price}
            ad={card.ad}
            promotion={card.promotion}
            onPurchaseClick={() => handlePurchaseClick(card.subscriptionType)}
            paymentCreateLoading={paymentCreateLoading}
            isSmaller={isSmaller}
          />
        ))}
      </div>
    </section>
  );
};

const mapStateToProps = (state: StoreState) => ({
  redirectUrl: state.payment.redirectUrl,
  isAuthenticated: state.auth.isAuthenticated,
  paymentCreateLoading: state.payment.paymentCreateLoading,
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCreatePayment: (
    inputs: PaymentCreateRequest,
    currentUser: User | null,
    intl: IntlShape,
  ) => dispatch(paymentService.createPayment(inputs, currentUser, intl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlansSection);
