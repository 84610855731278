import * as actionTypes from './actionTypes';
import { AnswerActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Answer } from '../../domain/Answer';

export type AnswerStateType = {
  answers: Record<number, Answer[] | null>;
  answersLoading: boolean;
  answersError: HttpError;
  answersListUpdateNeeded: boolean;
  answerCreateLoading: boolean;
  answerCreateError: HttpError;
  createdAnswer: Answer | null;
  answerUpdateLoading: boolean;
  answerUpdateError: HttpError;
  updatedAnswer: Answer | null;
  answerDeleteLoading: boolean;
  answerDeleteError: HttpError;
};

export type AnswerActionType = AnswerStateType & {
  type: AnswerActionTypes;
  questionId: number;
  answersList: Answer[] | null;
};

export const initialState: AnswerStateType = {
  answers: {},
  answersLoading: false,
  answersError: null,
  answersListUpdateNeeded: false,
  answerCreateLoading: false,
  answerCreateError: null,
  createdAnswer: null,
  answerUpdateLoading: false,
  answerUpdateError: null,
  updatedAnswer: null,
  answerDeleteLoading: false,
  answerDeleteError: null,
};

const fetchAnswersStart = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answersLoading: true,
  answerDeleteLoading: false,
  answerDeleteError: null,
});

const fetchAnswersSuccess = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => {
  const answers = { ...state.answers };

  answers[action.questionId] = action.answersList;

  return {
    ...state,
    answers: answers,
    answersLoading: false,
    answersError: null,
    answersListUpdateNeeded: false,
  };
};

const fetchAnswersFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answersError: action.answersError,
  answersLoading: false,
});

const createAnswerStart = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerCreateLoading: true,
});

const createAnswerSuccess = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerCreateLoading: false,
  answerCreateError: null,
  createdAnswer: action.createdAnswer,
  answersListUpdateNeeded: true,
});

const createAnswerFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerCreateLoading: false,
  answerCreateError: action.answerCreateError,
});

const updateAnswerStart = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerUpdateLoading: true,
});

const updateAnswerSuccess = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerUpdateLoading: false,
  answerUpdateError: null,
  answersListUpdateNeeded: true,
  updatedAnswer: action.updatedAnswer,
});

const updateAnswerFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerUpdateLoading: false,
  answerUpdateError: action.answerUpdateError,
});

const deleteAnswerStart = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerDeleteLoading: true,
});

const deleteAnswerSuccess = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  answerDeleteLoading: false,
  answerDeleteError: null,
  answersListUpdateNeeded: true,
});

const deleteAnswerFail = (
  state: AnswerStateType,
  action: AnswerActionType,
): AnswerStateType => ({
  ...state,
  answerDeleteLoading: false,
  answerDeleteError: action.answerDeleteError,
});

const resetCreatedAnswer = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  createdAnswer: null,
  answerCreateError: null,
  answerCreateLoading: false,
});
const resetUpdatedAnswer = (state: AnswerStateType): AnswerStateType => ({
  ...state,
  updatedAnswer: null,
  answerUpdateError: null,
  answerUpdateLoading: false,
});

const resetAnswerStore = (): AnswerStateType => ({
  ...initialState,
});

const logout = (): AnswerStateType => ({
  ...initialState,
});

const reducer = (
  state = initialState,
  action: AnswerActionType,
): AnswerStateType => {
  switch (action.type) {
    case actionTypes.FETCH_ANSWERS_START:
      return fetchAnswersStart(state);
    case actionTypes.FETCH_ANSWERS_SUCCESS:
      return fetchAnswersSuccess(state, action);
    case actionTypes.FETCH_ANSWERS_FAIL:
      return fetchAnswersFail(state, action);
    case actionTypes.CREATE_ANSWER_START:
      return createAnswerStart(state);
    case actionTypes.CREATE_ANSWER_SUCCESS:
      return createAnswerSuccess(state, action);
    case actionTypes.CREATE_ANSWER_FAIL:
      return createAnswerFail(state, action);
    case actionTypes.UPDATE_ANSWER_START:
      return updateAnswerStart(state);
    case actionTypes.UPDATE_ANSWER_SUCCESS:
      return updateAnswerSuccess(state, action);
    case actionTypes.UPDATE_ANSWER_FAIL:
      return updateAnswerFail(state, action);
    case actionTypes.DELETE_ANSWER_START:
      return deleteAnswerStart(state);
    case actionTypes.DELETE_ANSWER_SUCCESS:
      return deleteAnswerSuccess(state);
    case actionTypes.DELETE_ANSWER_FAIL:
      return deleteAnswerFail(state, action);
    case actionTypes.RESET_CREATED_ANSWER:
      return resetCreatedAnswer(state);
    case actionTypes.RESET_UPDATED_ANSWER:
      return resetUpdatedAnswer(state);
    case actionTypes.RESET_ANSWER_STORE:
      return resetAnswerStore();
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
