export const FETCH_CATEGORIES_LIST_START = 'FETCH_CATEGORIES_LIST_START';
export const FETCH_CATEGORIES_LIST_SUCCESS = 'FETCH_CATEGORIES_LIST_SUCCESS';
export const FETCH_CATEGORIES_LIST_FAIL = 'FETCH_CATEGORIES_LIST_FAIL';

export const FETCH_PUBLIC_CATEGORIES_START = 'FETCH_PUBLIC_CATEGORIES_START';
export const FETCH_PUBLIC_CATEGORIES_SUCCESS =
  'FETCH_PUBLIC_CATEGORIES_SUCCESS';
export const FETCH_PUBLIC_CATEGORIES_FAIL = 'FETCH_PUBLIC_CATEGORIES_FAIL';

export const FETCH_CATEGORY_START = 'FETCH_CATEGORY_START';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAIL = 'FETCH_CATEGORY_FAIL';

export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';

export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

export const UPDATE_CATEGORY_PRIORITY_START = 'UPDATE_CATEGORY_PRIORITY_START';
export const UPDATE_CATEGORY_PRIORITY_SUCCESS =
  'UPDATE_CATEGORY_PRIORITY_SUCCESS';
export const UPDATE_CATEGORY_PRIORITY_FAIL = 'UPDATE_CATEGORY_PRIORITY_FAIL';

export const RESET_CREATED_CATEGORY_STORE = 'RESET_CREATED_CATEGORY';
export const RESET_CATEGORY_STORE = 'RESET_CATEGORY_STORE';

export const LOGOUT = 'LOGOUT';

export type CategoryActionTypes =
  | typeof FETCH_CATEGORIES_LIST_START
  | typeof FETCH_CATEGORIES_LIST_SUCCESS
  | typeof FETCH_CATEGORIES_LIST_FAIL
  | typeof FETCH_PUBLIC_CATEGORIES_START
  | typeof FETCH_PUBLIC_CATEGORIES_SUCCESS
  | typeof FETCH_PUBLIC_CATEGORIES_FAIL
  | typeof FETCH_CATEGORY_START
  | typeof FETCH_CATEGORY_SUCCESS
  | typeof FETCH_CATEGORY_FAIL
  | typeof CREATE_CATEGORY_START
  | typeof CREATE_CATEGORY_SUCCESS
  | typeof CREATE_CATEGORY_FAIL
  | typeof UPDATE_CATEGORY_START
  | typeof UPDATE_CATEGORY_SUCCESS
  | typeof UPDATE_CATEGORY_FAIL
  | typeof DELETE_CATEGORY_START
  | typeof DELETE_CATEGORY_SUCCESS
  | typeof DELETE_CATEGORY_FAIL
  | typeof UPDATE_CATEGORY_PRIORITY_START
  | typeof UPDATE_CATEGORY_PRIORITY_SUCCESS
  | typeof UPDATE_CATEGORY_PRIORITY_FAIL
  | typeof RESET_CREATED_CATEGORY_STORE
  | typeof RESET_CATEGORY_STORE
  | typeof LOGOUT;
