import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import companyReducer, { CompanyStateType } from '../../store/company/reducer';
import userSettingReducer, {
  UserSettingStateType,
} from '../../store/user-setting/reducer';
import jobTitleReducer, {
  JobTitleStateType,
} from '../../store/job-title/reducer';
import employeeReducer, {
  EmployeeStateType,
} from '../../store/employee/reducer';
import testReducer, { TestStateType } from '../../store/test/reducer';
import questionReducer, {
  QuestionStateType,
} from '../../store/question/reducer';
import answerReducer, { AnswerStateType } from '../../store/answer/reducer';
import assessmentReducer, {
  AssessmentStateType,
} from '../../store/assessment/reducer';
import categoryReducer, {
  CategoryStateType,
} from '../../store/category/reducer';
import reviewReducer, { ReviewStateType } from '../../store/review/reducer';
import assessmentReviewReducer, {
  AssessmentReviewStateType,
} from '../../store/assessment-review/reducer';
import traitReducer, { TraitStateType } from '../../store/trait/reducer';
import userAnswerReducer, {
  UserAnswerStateType,
} from '../../store/user-answer/reducer';
import userAssessmentReducer, {
  UserAssessmentStateType,
} from '../../store/user-assessment/reducer';
import paymentReducer, { PaymentStateType } from '../../store/payment/reducer';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  company: CompanyStateType;
  userSetting: UserSettingStateType;
  jobTitle: JobTitleStateType;
  employee: EmployeeStateType;
  test: TestStateType;
  question: QuestionStateType;
  answer: AnswerStateType;
  assessment: AssessmentStateType;
  category: CategoryStateType;
  assessmentReview: AssessmentReviewStateType;
  review: ReviewStateType;
  trait: TraitStateType;
  userAnswer: UserAnswerStateType;
  userAssessment: UserAssessmentStateType;
  payment: PaymentStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  language: languageReducer,
  company: companyReducer,
  userSetting: userSettingReducer,
  jobTitle: jobTitleReducer,
  employee: employeeReducer,
  test: testReducer,
  question: questionReducer,
  answer: answerReducer,
  assessment: assessmentReducer,
  category: categoryReducer,
  assessmentReview: assessmentReviewReducer,
  review: reviewReducer,
  trait: traitReducer,
  userAnswer: userAnswerReducer,
  userAssessment: userAssessmentReducer,
  payment: paymentReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
