import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { UserAnswerActionTypes } from './actionTypes';

export type UserAnswerStateType = {
  userAnswerCreateLoading: boolean;
  userAnswerCreateError: HttpError;
  userAnswerCreateSuccess: boolean;
  userAnswer: string | null;
};

export type UserAnswerActionType = UserAnswerStateType & {
  type: UserAnswerActionTypes;
  answer: string;
};

export const initialState: UserAnswerStateType = {
  userAnswerCreateLoading: false,
  userAnswerCreateError: null,
  userAnswerCreateSuccess: false,
  userAnswer: null,
};

const createUserAnswerStart = (
  state: UserAnswerStateType,
): UserAnswerStateType => ({
  ...state,
  userAnswerCreateLoading: true,
});

const createUserAnswerSuccess = (
  state: UserAnswerStateType,
  action: UserAnswerActionType,
): UserAnswerStateType => ({
  ...state,
  userAnswerCreateLoading: false,
  userAnswerCreateError: null,
  userAnswerCreateSuccess: true,
  userAnswer: action.answer ?? '',
});

const createUserAnswerFail = (
  state: UserAnswerStateType,
  action: UserAnswerActionType,
): UserAnswerStateType => ({
  ...state,
  userAnswerCreateLoading: false,
  userAnswerCreateError: action.userAnswerCreateError,
  userAnswer: null,
});

const resetUserAnswerStore = (
  state: UserAnswerStateType,
): UserAnswerStateType => ({
  ...state,
  userAnswerCreateSuccess: false,
});

const completelyResetUserAnswerStore = (): UserAnswerStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: UserAnswerActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_ANSWER_START:
      return createUserAnswerStart(state);
    case actionTypes.CREATE_USER_ANSWER_SUCCESS:
      return createUserAnswerSuccess(state, action);
    case actionTypes.CREATE_USER_ANSWER_FAIL:
      return createUserAnswerFail(state, action);
    case actionTypes.USER_ANSWER_RESET_STORE:
      return resetUserAnswerStore(state);
    case actionTypes.LOGOUT:
      return completelyResetUserAnswerStore();
    default:
      return state;
  }
};

export default reducer;
