import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { routes } from '../../..//config/Router/routes';
import { StoreState } from '../../..//config/StoreProvider/StoreProvider';
import { getLocaleUrl } from '../../../utility/url/urlHelper';
import { Locale } from '../../../domain/Translation';
import styles from './LogoLink.module.scss';

type Props = {
  logoSrc: string;
  selectedLocale: Locale;
};

const LogoLink = ({ logoSrc, selectedLocale }: Props) => {
  return (
    <Link to={getLocaleUrl(routes.cvGorilla, selectedLocale)}>
      <img src={logoSrc} className={styles.logo} />
    </Link>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(LogoLink);
