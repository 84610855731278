import React, { useState } from 'react';
import styles from './LanguageSwitcher.module.scss';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { selectLocale } from '../../store/auth/actions';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Popover } from '@mui/material';
import { Locale } from '../../domain/Translation';
import { CircleFlag } from 'react-circle-flags';
import { Language } from '../../domain/Language';

type Props = {
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  allLanguages: Language[];
};

const LanguageSwitcher = ({
  onSelectLocale,
  selectedLocale,
  allLanguages,
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const allEnabledLanguages = allLanguages.filter(
    (language) => language.isEnabled,
  );

  const getCountryCode = (locale: string): string => {
    switch (locale) {
      case 'lt':
        return 'lt';
      case 'en':
        return 'uk';
      case 'fr':
        return 'fr';
      case 'lv':
        return 'lv';
      case 'pl':
        return 'pl';
      case 'de':
        return 'de';
      case 'da':
        return 'dk';
      case 'sv':
        return 'se';
      case 'el':
        return 'gr';
      case 'it':
        return 'it';
      case 'pt':
        return 'pt';
      case 'ja':
        return 'jp';
      case 'ko':
        return 'kr';
      case 'ar':
        return 'ae';
      case 'zh':
        return 'cn';
      case 'hi':
        return 'in';
      default:
        return 'uk';
    }
  };

  const selectedLocaleOption = allEnabledLanguages.find(
    (language) => language.locale === selectedLocale,
  );

  const handleClick = (locale: Locale) => {
    onSelectLocale(locale);
    setIsPopoverOpen(false);
  };

  const renderActions = () => {
    return allEnabledLanguages.map((language, index) => (
      <button
        className={styles.popoverListItem}
        key={index.toString()}
        onClick={() => handleClick(language.locale as Locale)}
      >
        {
          <CircleFlag
            countryCode={getCountryCode(language.locale)}
            height="35"
          />
        }
      </button>
    ));
  };

  return (
    <>
      <div
        className={styles.switcherButton}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setIsPopoverOpen(true);
        }}
      >
        {selectedLocaleOption && (
          <CircleFlag
            countryCode={getCountryCode(selectedLocaleOption.locale)}
            height="35"
          />
        )}
      </div>
      <Popover
        className={styles.popover}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={styles.popoverContent}>
          <ul className={styles.popoverList}>{renderActions()}</ul>
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  allLanguages: state.language.languages,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
