import React from 'react';
import stylesPlanCard from './PlanCard.module.scss';
import stylesPreviewPlanCard from '../../AssessmentSection/PlansPreview/PreviewPlanCard/PreviewPlanCard.module.scss';
import { useIntl } from 'react-intl';
import { translate } from '../../../../../utility/messageTranslator/translate';
import Button from '../../../../../common/Button/Button';
import checkmark from '../../../../../assets/checkmark-green.svg';
import cx from 'classnames';

type Props = {
  title: string;
  pros: string[];
  subtitle: string;
  description: string;
  price: string;
  isSubtitleBelow?: boolean;
  ad?: string;
  promotion?: boolean;
  isPreviewCard?: boolean;
  onPurchaseClick?: () => void;
  paymentCreateLoading?: boolean;
  isSmaller?: boolean;
};

const PlanCard = ({
  title,
  pros,
  subtitle,
  description,
  price,
  isSubtitleBelow,
  ad,
  promotion,
  isPreviewCard,
  onPurchaseClick,
  paymentCreateLoading,
  isSmaller,
}: Props) => {
  const intl = useIntl();
  const styles = isPreviewCard ? stylesPreviewPlanCard : stylesPlanCard;

  return (
    <div
      className={cx(styles.cardContainer, {
        [styles.smaller]: isSmaller,
      })}
    >
      <div>
        <div className={styles.title}>{translate(intl, `${title}`)}</div>
        {!isSubtitleBelow && (
          <div className={styles.subtitle}>
            {translate(intl, `${subtitle}`)}
          </div>
        )}
        <div
          className={cx(styles.prosContainer, {
            [styles.prosAdditionalStyling]: isSubtitleBelow,
          })}
        >
          {pros.map((pros) => (
            <div className={styles.pros} key={pros}>
              <img
                src={checkmark}
                alt="checkmark"
                className={styles.checkmark}
              />
              {translate(intl, `${pros}`)}
            </div>
          ))}
        </div>
        {isSubtitleBelow && (
          <div className={styles.subtitle}>
            {translate(intl, `${subtitle}`)}
          </div>
        )}
        <div className={styles.description}>
          {translate(intl, `${description}`)}
        </div>
      </div>
      <div>
        <div
          className={styles.price}
          dangerouslySetInnerHTML={{
            __html: translate(intl, `${price}`),
          }}
        />
        {ad && <div className={styles.ad}>{translate(intl, `${ad}`)}</div>}
        {promotion && (
          <div className={styles.promotion}>
            {translate(intl, 'PLANS_CARD.PROMOTION')}
          </div>
        )}
        <Button
          className={styles.tryButton}
          type="button"
          onClick={() => onPurchaseClick?.()}
          isLoadingButton
          isLoading={paymentCreateLoading}
        >
          {translate(intl, 'PLANS_CARD.TRY_BUTTON')}
        </Button>
      </div>
    </div>
  );
};

export default PlanCard;
