import * as actionTypes from './actionTypes';
import { AssessmentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Assessment, AssessmentPreview } from '../../domain/Assessment';
import {
  LOCAL_STORAGE_SAVED_ASSESSMENTS,
  LOCAL_STORAGE_SAVED_PUBLIC_ASSESSMENT_DATA,
} from '../../config/constants';
import { PublicAssessment } from '../../domain/PublicAssessment';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../utility/localStorage/localStorageHelper';
import { Candidate } from '../../domain/Candidate';

const localStorageAssessments = getLocalStorageItem<number[]>(
  LOCAL_STORAGE_SAVED_ASSESSMENTS,
);

const savedPublicAssessmentData =
  getLocalStorageItem<PublicAssessmentData | null>(
    LOCAL_STORAGE_SAVED_PUBLIC_ASSESSMENT_DATA,
  );

export type PublicAssessmentData = {
  startDate: Date;
  assessmentId: number;
  questions: Array<{ id: number; answerId: number }>;
};

export type AssessmentStateType = {
  assessmentsList: ListResults<Assessment> | null;
  assessmentsLoading: boolean;
  assessmentsError: HttpError;
  publicAssessmentsList: ListResults<Assessment> | null;
  publicAssessmentsLoading: boolean;
  publicAssessmentsError: HttpError;
  assessmentsListUpdateNeeded: boolean;
  assessmentCreateLoading: boolean;
  assessmentCreateError: HttpError;
  assessmentCreateSuccess: boolean;
  assessmentUpdateLoading: boolean;
  assessmentUpdateError: HttpError;
  assessmentUpdateSuccess: boolean;
  assessmentDeleteLoading: boolean;
  assessmentDeleteError: HttpError;
  assessment: Assessment | null;
  assessmentLoading: boolean;
  assessmentError: HttpError;
  assessmentRandom: Assessment | null;
  assessmentRandomLoading: boolean;
  assessmentRandomError: HttpError;
  assessmentPreviews: AssessmentPreview[];
  assessmentPreviewsLoading: boolean;
  assessmentPreviewsError: HttpError;
  publicAssessment: PublicAssessment | null;
  publicAssessmentLoading: boolean;
  publicAssessmentError: HttpError;
  savedAssessments: number[] | [];
  savedPublicAssessmentData: PublicAssessmentData | null;
  assessmentCandidates: Candidate[];
  assessmentCandidatesLoading: boolean;
  assessmentCandidatesError: HttpError;
};

export type AssessmentActionType = AssessmentStateType & {
  type: AssessmentActionTypes;
};

export const initialState: AssessmentStateType = {
  assessmentsList: null,
  assessmentsLoading: true,
  assessmentsListUpdateNeeded: false,
  assessmentsError: null,
  publicAssessmentsList: null,
  publicAssessmentsLoading: false,
  publicAssessmentsError: null,
  assessmentCreateLoading: false,
  assessmentCreateError: null,
  assessmentCreateSuccess: false,
  assessmentUpdateLoading: false,
  assessmentUpdateError: null,
  assessmentUpdateSuccess: false,
  assessmentDeleteLoading: false,
  assessmentDeleteError: null,
  assessment: null,
  assessmentLoading: false,
  assessmentError: null,
  assessmentRandom: null,
  assessmentRandomLoading: false,
  assessmentRandomError: null,
  assessmentPreviews: [],
  assessmentPreviewsLoading: true,
  assessmentPreviewsError: null,
  publicAssessment: null,
  publicAssessmentLoading: false,
  publicAssessmentError: null,
  savedPublicAssessmentData: savedPublicAssessmentData,
  savedAssessments: localStorageAssessments ?? [],
  assessmentCandidates: [],
  assessmentCandidatesLoading: false,
  assessmentCandidatesError: null,
};

const fetchAssessmentsStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentsLoading: true,
});

const fetchAssessmentsSuccess = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentsList: action.assessmentsList,
  assessmentsLoading: false,
  assessmentsError: null,
  assessmentsListUpdateNeeded: false,
  assessmentCreateSuccess: false,
  assessmentUpdateSuccess: false,
});

const fetchAssessmentsFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentsError: action.assessmentsError,
  assessmentsLoading: false,
});

const fetchPublicAssessmentsStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  publicAssessmentsLoading: true,
});

const fetchPublicAssessmentsSuccess = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  publicAssessmentsList: action.publicAssessmentsList,
  publicAssessmentsLoading: false,
  publicAssessmentsError: null,
});

const fetchPublicAssessmentsFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  publicAssessmentsError: action.publicAssessmentsError,
  publicAssessmentsLoading: false,
});

const fetchAssessmentStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentLoading: true,
  assessmentUpdateSuccess: false,
  assessmentCreateSuccess: false,
  assessmentCreateError: null,
  assessmentUpdateError: null,
});

const fetchAssessmentSuccess = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessment: action.assessment,
  assessmentLoading: false,
  assessmentError: null,
});

const fetchAssessmentFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentError: action.assessmentError,
  assessmentLoading: false,
});

const fetchRandomAssessmentStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentRandomLoading: true,
});

const fetchRandomAssessmentSuccess = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentRandom: action.assessmentRandom,
  assessmentRandomLoading: false,
  assessmentRandomError: null,
});

const fetchRandomAssessmentFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentRandomError: action.assessmentRandomError,
  assessmentRandomLoading: false,
});

const fetchAssessmentPreviewsStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentPreviewsLoading: true,
});

const fetchAssessmentPreviewsSuccess = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentPreviews: action.assessmentPreviews,
  assessmentPreviewsLoading: false,
  assessmentPreviewsError: null,
});

const fetchAssessmentPreviewsFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentPreviewsError: action.assessmentPreviewsError,
  assessmentPreviewsLoading: false,
});

const createAssessmentStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentCreateLoading: true,
});

const createAssessmentSuccess = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentCreateLoading: false,
  assessmentCreateError: null,
  assessmentsListUpdateNeeded: true,
  assessmentCreateSuccess: true,
});

const createAssessmentFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentCreateLoading: false,
  assessmentCreateError: action.assessmentCreateError,
});

const updateAssessmentStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentUpdateLoading: true,
});

const updateAssessmentSuccess = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentUpdateLoading: false,
  assessmentUpdateError: null,
  assessmentsListUpdateNeeded: true,
  assessmentUpdateSuccess: true,
});

const updateAssessmentFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentUpdateLoading: false,
  assessmentUpdateError: action.assessmentUpdateError,
});

const deleteAssessmentStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentDeleteLoading: true,
});

const deleteAssessmentSuccess = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentDeleteLoading: false,
  assessmentDeleteError: null,
  assessmentsListUpdateNeeded: true,
});

const deleteAssessmentFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentDeleteLoading: false,
  assessmentDeleteError: action.assessmentDeleteError,
});

const fetchPublicAssessmentStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  publicAssessmentLoading: true,
});

const fetchPublicAssessmentSuccess = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  publicAssessment: action.publicAssessment,
  publicAssessmentLoading: false,
  publicAssessmentError: null,
});

const fetchPublicAssessmentFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  publicAssessmentError: action.publicAssessmentError,
  publicAssessmentLoading: false,
});

const resetPublicAssessment = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  publicAssessment: null,
  publicAssessmentLoading: false,
  publicAssessmentError: null,
});

const setSavedAssessments = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => {
  const addedAssessments = action.savedAssessments;

  setLocalStorageItem(LOCAL_STORAGE_SAVED_ASSESSMENTS, addedAssessments);

  return {
    ...state,
    savedAssessments: addedAssessments,
  };
};

const fetchAssessmentCandidatesStart = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentCandidatesLoading: true,
});

const fetchAssessmentCandidatesSuccess = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentCandidates: action.assessmentCandidates,
  assessmentCandidatesLoading: false,
  assessmentCandidatesError: null,
});

const fetchAssessmentCandidatesFail = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => ({
  ...state,
  assessmentCandidatesError: action.assessmentCandidatesError,
  assessmentCandidatesLoading: false,
});

const resetAssessmentCandidates = (
  state: AssessmentStateType,
): AssessmentStateType => ({
  ...state,
  assessmentCandidates: [],
  assessmentCandidatesLoading: false,
  assessmentCandidatesError: null,
});

const setSavedPublicAssessmentData = (
  state: AssessmentStateType,
  action: AssessmentActionType,
): AssessmentStateType => {
  const addedAssessments = action.savedPublicAssessmentData;

  if (state.savedPublicAssessmentData && addedAssessments) {
    const updatedAssessmentData = {
      ...state.savedPublicAssessmentData,
      questions: [...state.savedPublicAssessmentData.questions],
    };

    for (const question of addedAssessments.questions) {
      const existingQuestion = updatedAssessmentData.questions.find(
        (updatedQuestion) => updatedQuestion.id === question.id,
      );

      if (existingQuestion) {
        existingQuestion.answerId = question.answerId;
      } else {
        updatedAssessmentData.questions.push(question);
      }
    }

    setLocalStorageItem(
      LOCAL_STORAGE_SAVED_PUBLIC_ASSESSMENT_DATA,
      updatedAssessmentData,
    );

    return {
      ...state,
      savedPublicAssessmentData: updatedAssessmentData,
    };
  }

  setLocalStorageItem(
    LOCAL_STORAGE_SAVED_PUBLIC_ASSESSMENT_DATA,
    addedAssessments,
  );

  return {
    ...state,
    savedPublicAssessmentData: addedAssessments,
  };
};

const resetPublicAssessmentData = (
  state: AssessmentStateType,
): AssessmentStateType => {
  localStorage.removeItem(LOCAL_STORAGE_SAVED_PUBLIC_ASSESSMENT_DATA);

  return {
    ...state,
    savedPublicAssessmentData: null,
  };
};

const resetAssessmentStore = (): AssessmentStateType => ({
  ...initialState,
});

const completelyResetAssessmentStore = (): AssessmentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: AssessmentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSESSMENTS_START:
      return fetchAssessmentsStart(state);
    case actionTypes.FETCH_ASSESSMENTS_SUCCESS:
      return fetchAssessmentsSuccess(state, action);
    case actionTypes.FETCH_ASSESSMENTS_FAIL:
      return fetchAssessmentsFail(state, action);
    case actionTypes.FETCH_PUBLIC_ASSESSMENTS_START:
      return fetchPublicAssessmentsStart(state);
    case actionTypes.FETCH_PUBLIC_ASSESSMENTS_SUCCESS:
      return fetchPublicAssessmentsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_ASSESSMENTS_FAIL:
      return fetchPublicAssessmentsFail(state, action);
    case actionTypes.FETCH_ASSESSMENT_START:
      return fetchAssessmentStart(state);
    case actionTypes.FETCH_ASSESSMENT_SUCCESS:
      return fetchAssessmentSuccess(state, action);
    case actionTypes.FETCH_ASSESSMENT_FAIL:
      return fetchAssessmentFail(state, action);
    case actionTypes.FETCH_RANDOM_ASSESSMENT_START:
      return fetchRandomAssessmentStart(state);
    case actionTypes.FETCH_RANDOM_ASSESSMENT_SUCCESS:
      return fetchRandomAssessmentSuccess(state, action);
    case actionTypes.FETCH_RANDOM_ASSESSMENT_FAIL:
      return fetchRandomAssessmentFail(state, action);
    case actionTypes.FETCH_ASSESSMENT_PREVIEWS_START:
      return fetchAssessmentPreviewsStart(state);
    case actionTypes.FETCH_ASSESSMENT_PREVIEWS_SUCCESS:
      return fetchAssessmentPreviewsSuccess(state, action);
    case actionTypes.FETCH_ASSESSMENT_PREVIEWS_FAIL:
      return fetchAssessmentPreviewsFail(state, action);
    case actionTypes.CREATE_ASSESSMENT_START:
      return createAssessmentStart(state);
    case actionTypes.CREATE_ASSESSMENT_SUCCESS:
      return createAssessmentSuccess(state);
    case actionTypes.CREATE_ASSESSMENT_FAIL:
      return createAssessmentFail(state, action);
    case actionTypes.UPDATE_ASSESSMENT_START:
      return updateAssessmentStart(state);
    case actionTypes.UPDATE_ASSESSMENT_SUCCESS:
      return updateAssessmentSuccess(state);
    case actionTypes.UPDATE_ASSESSMENT_FAIL:
      return updateAssessmentFail(state, action);
    case actionTypes.DELETE_ASSESSMENT_START:
      return deleteAssessmentStart(state);
    case actionTypes.DELETE_ASSESSMENT_SUCCESS:
      return deleteAssessmentSuccess(state);
    case actionTypes.DELETE_ASSESSMENT_FAIL:
      return deleteAssessmentFail(state, action);
    case actionTypes.FETCH_PUBLIC_ASSESSMENT_START:
      return fetchPublicAssessmentStart(state);
    case actionTypes.FETCH_PUBLIC_ASSESSMENT_SUCCESS:
      return fetchPublicAssessmentSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_ASSESSMENT_FAIL:
      return fetchPublicAssessmentFail(state, action);
    case actionTypes.RESET_PUBLIC_ASSESSMENT:
      return resetPublicAssessment(state);
    case actionTypes.FETCH_ASSESSMENT_CANDIDATES_START:
      return fetchAssessmentCandidatesStart(state);
    case actionTypes.FETCH_ASSESSMENT_CANDIDATES_SUCCESS:
      return fetchAssessmentCandidatesSuccess(state, action);
    case actionTypes.FETCH_ASSESSMENT_CANDIDATES_FAIL:
      return fetchAssessmentCandidatesFail(state, action);
    case actionTypes.RESET_ASSESSMENT_CANDIDATES:
      return resetAssessmentCandidates(state);
    case actionTypes.RESET_ASSESSMENT_STORE:
      return resetAssessmentStore();
    case actionTypes.SET_SAVED_ASSESSMENTS:
      return setSavedAssessments(state, action);
    case actionTypes.SET_SAVED_PUBLIC_ASSESSMENT_DATA:
      return setSavedPublicAssessmentData(state, action);
    case actionTypes.RESET_SAVED_PUBLIC_ASSESSMENT_DATA:
      return resetPublicAssessmentData(state);
    case actionTypes.LOGOUT:
      return completelyResetAssessmentStore();
    default:
      return state;
  }
};

export default reducer;
