import _ from 'lodash';

export const getLocalStorageItem = <T>(storageKey: string): T | null => {
  const storageItem = localStorage.getItem(storageKey);

  if (storageItem) {
    try {
      const parsedItem = JSON.parse(storageItem);

      if (_.isObject(parsedItem) && typeof ({} as T) === 'object') {
        return parsedItem as T;
      }

      return parsedItem;
    } catch (error) {
      return null;
    }
  }

  return null;
};

export const setLocalStorageItem = <T>(storageKey: string, item: T) => {
  localStorage.setItem(storageKey, JSON.stringify(item));
};
