export const routes = {
  // homepage: '/',
  privacyPolicy: '/privacy-policy',
  login: '/login',
  register: '/register',
  remind: '/remind-password',
  resetPassword: '/password-reset/:code',
  profile: '/profile',
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  companies: {
    list: '/admin/companies',
    edit: '/admin/companies/:id',
    create: '/admin/companies/create',
  },
  jobTitles: {
    list: '/admin/job-titles',
  },
  tests: {
    list: '/admin/tests',
  },
  questions: {
    list: '/admin/questions',
    edit: '/admin/question/:id',
  },
  translations: '/admin/translations',
  comingSoon: '/coming-soon',
  employees: {
    list: '/admin/employees',
    edit: '/admin/employees/:id',
  },
  assessments: {
    list: '/admin/assessments',
    create: '/admin/assessments/new',
    edit: '/admin/assessments/:id',
  },
  investment: '/investment',
  publicAssessments: '/',
  categories: {
    list: '/admin/categories',
  },
  traits: {
    list: '/admin/traits',
  },
  reviews: {
    list: '/admin/reviews',
  },
  publicAssessment: {
    assessmentPage: '/assessments/:id',
    resultsPage: '/assessments/:token/results',
    completion: '/assessments/:id/completion',
  },
  resultsPage: '/assessments/:id/results',
  languages: '/admin/languages',
  cvGorilla: 'https://cvgorilla.com/',
};
