import React, { useEffect, useState } from 'react';
import styles from './PublicNavigation.module.scss';
import logo from '../../assets/cvgorilla_logo.png';
import LogoLink from './LogoLink/LogoLink';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import CommonLinks from './CommonLinks/CommonLinks';
import HamburgerButton from './HamburgerButton/HamburgerButton';
import { useLocation } from 'react-router-dom';

export const MOBILE_BREAK_POINT = 1200;

const PublicNavigation = () => {
  const { width } = useWindowSize();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (width && width > MOBILE_BREAK_POINT) {
      setIsMobileMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.key]);

  return (
    <header className={styles.navigationContainer}>
      <div className={styles.innerNavigation}>
        <LogoLink logoSrc={logo} />

        {width && width >= MOBILE_BREAK_POINT && <CommonLinks />}
        {width && width < MOBILE_BREAK_POINT && (
          <HamburgerButton
            isMobileMenuOpen={isMobileMenuOpen}
            clickHandler={() => setIsMobileMenuOpen((prevState) => !prevState)}
          />
        )}
      </div>

      {width && width < MOBILE_BREAK_POINT && isMobileMenuOpen && (
        <div className={styles.mobileMenuLinks}>
          <CommonLinks />
        </div>
      )}
    </header>
  );
};

export default PublicNavigation;
