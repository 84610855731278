import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import { SignUpSectionType } from '../../pages/Public/SignUpPage/SignUpPage';
import { getLocaleFromUrl } from '../../utility/url/urlHelper';

const SignUpPage = React.lazy(
  () => import('../../pages/Public/SignUpPage/SignUpPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);

// const HomePageV2 = React.lazy(
//   () => import('../../pages/Public/HomePageV2/HomePageV2'),
// );

const PrivacyPolicyPage = React.lazy(
  () => import('../../pages/Public/PrivacyPolicyPage/PrivacyPolicyPage'),
);

const CompaniesListPage = React.lazy(
  () => import('../../pages/Admin/Company/CompaniesListPage/CompaniesListPage'),
);

const CompanyEditPage = React.lazy(
  () => import('../../pages/Admin/Company/CompanyEditPage/CompanyEditPage'),
);

const ComingSoonPage = React.lazy(
  () => import('../../pages/Public/ComingSoonPage/ComingSoonPage'),
);

const JobTitleListPage = React.lazy(
  () => import('../../pages/Admin/JobTitlesPage/JobTitlesPage'),
);

const EmployeesListPage = React.lazy(
  () =>
    import('../../pages/Admin/Employee/EmployeesListPage/EmployeesListPage'),
);

const TestsListPage = React.lazy(
  () => import('../../pages/Admin/Test/TestsListPage/TestsListPage'),
);

const QuestionEditPage = React.lazy(
  () => import('../../pages/Admin/Question/QuestionEditPage/QuestionEditPage'),
);

const EmployeeEditPage = React.lazy(
  () => import('../../pages/Admin/Employee/EmployeeEditPage/EmployeeEditPage'),
);

const InvestmentPage = React.lazy(
  () => import('../../pages/Public/InvestmentPage/InvestmentPage'),
);

const AssessmentsListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Assessment/AssessmentListPage/AssessmentListPage'
    ),
);

const AssessmentCreatePage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Assessment/AssessmentCreatePage/AssessmentsCreatePage'
    ),
);

const AssessmentEditPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Assessment/AssessmentEditPage/AssessmentEditPage'
    ),
);

const TestsPage = React.lazy(
  () => import('../../pages/Public/TestsPage/TestsPage'),
);

const AssessmentPage = React.lazy(
  () => import('../../pages/Public/AssessmentPage/AssessmentPage'),
);

const CategoryListPage = React.lazy(
  () =>
    import('../../pages/Admin/Category/CategoriesListPage/CategoriesListPage'),
);

const PasswordResetPage = React.lazy(
  () => import('../../pages/Public/PasswordResetPage/PasswordResetPage'),
);

const TraitsListPage = React.lazy(
  () => import('../../pages/Admin/Trait/TraistListPage/TraitsListPage'),
);

const ReviewsListPage = React.lazy(
  () => import('../../pages/Admin/Review/ReviewsListPage/ReviewsListPage'),
);

const QuestionsListPage = React.lazy(
  () =>
    import('../../pages/Admin/Question/QuestionsListPage/QuestionsListPage'),
);

const ResultsPage = React.lazy(
  () => import('../../pages/Public/ResultsPage/ResultsPage'),
);

const ProfilePage = React.lazy(
  () => import('../../pages/Public/ProfilePage/ProfilePage'),
);

const AssessmentCompletionPage = React.lazy(
  () =>
    import(
      '../../pages/Public/AssessmentCompletionPage/AssessmentCompletionPage'
    ),
);

const LanguagesListPage = React.lazy(
  () => import('../../pages/Admin/Language/LanguagesListPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  currentUser: User | null;
  selectedCompany: number | null;
  onLanguagesInit: () => void;
  languages: Language[];
};

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  selectedLocale,
  onSelectLocale,
  currentUser,
  selectedCompany,
  onLanguagesInit,
  languages,
}: Props) => {
  const parsedLocale = getLocaleFromUrl(window.location.hostname) as Locale;

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!parsedLocale) {
      onLanguageFetch(parsedLocale ?? DEFAULT_LOCALE);
      return;
    }

    moment.locale(parsedLocale === 'en' ? 'en-gb' : parsedLocale);
    onLanguageFetch(parsedLocale);
    onLanguagesInit();

    if (!localStorage.getItem('selectedLocale')) {
      onSelectLocale(parsedLocale);
    }
  }, [parsedLocale]);

  useEffect(() => {
    if (selectedLocale) {
      onLanguageFetch(selectedLocale);
    }
  }, [selectedLocale]);

  const mappedTranslations = language?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route path={routes.login} element={<SignUpPage />} />
          <Route
            path={routes.register}
            element={<SignUpPage sectionType={SignUpSectionType.REGISTER} />}
          />
          {/* <Route path={routes.homepage} element={<HomePageV2 />} /> */}
          <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
          <Route path={routes.comingSoon} element={<ComingSoonPage />} />
          <Route path={routes.investment} element={<InvestmentPage />} />
          <Route path={routes.publicAssessments} element={<TestsPage />} />
          <Route
            path={routes.publicAssessment.assessmentPage}
            element={<AssessmentPage />}
          />
          <Route path={routes.resetPassword} element={<PasswordResetPage />} />
          <Route
            path={routes.publicAssessment.resultsPage}
            element={<ResultsPage />}
          />
          <Route
            path={routes.publicAssessment.completion}
            element={<AssessmentCompletionPage />}
          />
          <Route path="*" element={<Navigate to={routes.login} />} />
        </>
      );
    }

    if (currentUser && currentUser.role === Roles.USER) {
      return (
        <>
          <Route path={routes.comingSoon} element={<ComingSoonPage />} />
          {/* <Route path={routes.homepage} element={<HomePageV2 />} /> */}
          <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
          <Route path={routes.investment} element={<InvestmentPage />} />
          <Route path={routes.publicAssessments} element={<TestsPage />} />
          <Route
            path={routes.publicAssessment.assessmentPage}
            element={<AssessmentPage />}
          />
          <Route
            path={routes.publicAssessment.resultsPage}
            element={<ResultsPage />}
          />
          <Route
            path={routes.publicAssessment.completion}
            element={<AssessmentCompletionPage />}
          />
          <Route path={routes.profile} element={<ProfilePage />} />
          <Route path={routes.dynamicPage} element={<DynamicPage />} />
          <Route path="*" element={<Navigate to={routes.profile} />} />
        </>
      );
    }

    return (
      <>
        {selectedCompany &&
          currentUser &&
          (currentUser.role === Roles.ADMIN ||
            currentUser.role === Roles.OWNER) && (
            <>
              <Route
                path={routes.jobTitles.list}
                element={<JobTitleListPage />}
              />
              <Route
                path={routes.employees.list}
                element={<EmployeesListPage />}
              />
              <Route
                path={routes.employees.edit}
                element={<EmployeeEditPage />}
              />
            </>
          )}
        <Route
          path={routes.admin}
          element={<Navigate replace to={routes.companies.list} />}
        />
        <Route path={routes.users.create} element={<UserCreatePage />} />
        <Route path={routes.users.edit} element={<UserEditPage />} />
        <Route path={routes.users.list} element={<UsersListPage />} />
        <Route path={routes.translations} element={<TranslationsPage />} />
        <Route path={routes.dynamicPage} element={<DynamicPage />} />
        {/* <Route path={routes.homepage} element={<HomePageV2 />} /> */}
        <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
        <Route path={routes.companies.list} element={<CompaniesListPage />} />
        <Route path={routes.companies.edit} element={<CompanyEditPage />} />
        <Route path={routes.tests.list} element={<TestsListPage />} />
        <Route path={routes.questions.list} element={<QuestionsListPage />} />
        <Route path={routes.questions.edit} element={<QuestionEditPage />} />
        <Route path={routes.investment} element={<InvestmentPage />} />
        <Route path={routes.publicAssessments} element={<TestsPage />} />
        <Route
          path={routes.publicAssessment.assessmentPage}
          element={<AssessmentPage />}
        />
        <Route path={routes.categories.list} element={<CategoryListPage />} />
        <Route
          path={routes.assessments.list}
          element={<AssessmentsListPage />}
        />
        <Route
          path={routes.assessments.create}
          element={<AssessmentCreatePage />}
        />
        <Route
          path={routes.assessments.edit}
          element={<AssessmentEditPage />}
        />
        <Route
          path={routes.publicAssessment.assessmentPage}
          element={<AssessmentPage />}
        />
        <Route path={routes.traits.list} element={<TraitsListPage />} />
        <Route path={routes.reviews.list} element={<ReviewsListPage />} />
        <Route
          path={routes.publicAssessment.resultsPage}
          element={<ResultsPage />}
        />
        <Route
          path={routes.publicAssessment.completion}
          element={<AssessmentCompletionPage />}
        />
        <Route path={routes.profile} element={<ProfilePage />} />
        <Route path={routes.languages} element={<LanguagesListPage />} />
        <Route path="*" element={<Navigate to={routes.profile} />} />
      </>
    );
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout>
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
  selectedCompany: state.company.selectedCompany,
  languages: state.language.languages,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
