export const FETCH_ASSESSMENT_REVIEWS_START = 'FETCH_ASSESSMENT_REVIEWS_START';
export const FETCH_ASSESSMENT_REVIEWS_SUCCESS =
  'FETCH_ASSESSMENT_REVIEWS_SUCCESS';
export const FETCH_ASSESSMENT_REVIEWS_FAIL = 'FETCH_ASSESSMENT_REVIEWS_FAIL';
export const RESET_ASSESSMENT_REVIEWS_STORE = 'RESET_ASSESSMENT_REVIEWS_STORE';

export type AssessmentReviewActionTypes =
  | typeof FETCH_ASSESSMENT_REVIEWS_START
  | typeof FETCH_ASSESSMENT_REVIEWS_SUCCESS
  | typeof FETCH_ASSESSMENT_REVIEWS_FAIL
  | typeof RESET_ASSESSMENT_REVIEWS_STORE;
